import React, { useState, useEffect } from 'react';
import Wrapper from '../Components/Wrapper/Wrapper';
import { Button, Grid, Container } from '@mantine/core';
import '@mantine/core/styles.css';
import CreateJobForm from '../Components/CreateJobForm/CreateJobForm.jsx';
import JobFAQ from '../Components/JobFAQ/JobFAQ.jsx';
import JobDescription from '../Components/JobDescription/JobDescription.jsx';
import { createJobPosting, getJobPostingById, updateJobPosting } from '../services/apiJobPostings';
import { useParams, useMatch, useNavigate } from 'react-router-dom';
import { getProgramsByJobId, createProgram, updateProgram } from '../services/apiPrograms.js';
import { createProgramElement } from '../services/apiProgramElements'

export default function CreateJob() {
    const { id } = useParams();
    const match = useMatch('/job/edit/:id');

    const [jobPosting, setJobPosting] = useState([]);
    const [programByJobId, setProgramByJobId] = useState({});
    const [description, setDescription] = useState('');
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchJobPosting = async () => {
            const jobPosting = await getJobPostingById(id);
            setJobPosting(jobPosting);
        }
        if (match) {
            fetchJobPosting();
        }
    }, [id, match]);

    useEffect(() => {
        const fetchProgramsByJobId = async () => {
            const programsByJobId = await getProgramsByJobId(jobPosting?.id);

            if (programsByJobId && programsByJobId.length > 0) {
                setProgramByJobId(programsByJobId[0]);
                setDescription(programsByJobId[0].documentText);
            }
        }
        if (jobPosting && jobPosting.id) {
            fetchProgramsByJobId();
        }
    }, [jobPosting, jobPosting.id]);

    useEffect(() => {
        const fetchProgramsByJobId = async () => {
            const programsByJobId = await getProgramsByJobId(jobPosting?.id);

            if (programsByJobId && programsByJobId.length > 0) {
                setProgramByJobId(programsByJobId[0]);
            }
        }

        const handleEvent = (event) => {
            if (event.data.type === 'programUpdated') {
                fetchProgramsByJobId().then(() => {
                    setJobPosting(prevState => ({
                        ...prevState,
                        jobDescription: programByJobId.documentText
                    }));
                });
            }
        }

        window.addEventListener('message', handleEvent);

        return () => {
            window.removeEventListener('message', handleEvent);
        }
    });

    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        setVisible(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = {};
        for (const [name, value] of formData) {
            data[name] = value;
        }

        if (match) {
            // Update job posting
            const updatedJobPosting = {
                ...jobPosting,
                jobTitle: data.jobTitle,
                jobDescription: data.jobDescription,
                location: data.location,
                salaryRange: data.salaryRange,
            };
            const jobPostingResponse = await updateJobPosting(jobPosting.id, updatedJobPosting);

            if (programByJobId && programByJobId.id) {
                const updatedProgram = {
                    ...programByJobId,
                    contextText: `<jobTitle> ${jobPostingResponse.jobTitle} </jobTitle> <jobLocation> ${jobPostingResponse.location} </jobLocation> <jobSalaryRange> ${jobPostingResponse.salaryRange} </jobSalaryRange>`,
                    documentText: `${jobPostingResponse.jobDescription}`,
                };
                await updateProgram(updatedProgram);
            }

            // Redirect to /job/edit/:id
            navigate(`/job/edit/${jobPostingResponse.id}`);
        } else {

            // manually add companyName
            data.companyName = 'Marty Chefman Inc.';
            data.jobDescription = 'Please insert description here.';

            // Create job posting
            const jobPostingResponse = await createJobPosting(data);

            // Create program with the returned jobPostingId
            const program = await createProgram({
                programName: 'Program for ' + jobPostingResponse.jobTitle, // replace with actual programName
                locations: [], // replace with actual locations
                contextText: `<jobTitle> ${jobPostingResponse.jobTitle} </jobTitle> <jobLocation> ${jobPostingResponse.location} </jobLocation> <jobSalaryRange> ${jobPostingResponse.salaryRange} </jobSalaryRange>`,
                documentText: `${jobPostingResponse.jobDescription}`,
                jobPostingId: jobPostingResponse.id,
            });

            // initiate conversation
            await createProgramElement({
                programId: program.id,
                elementBody: { message: 'Hello' },
                type: 'jobdescription'
            });
            // Redirect to /job/edit/:id
            navigate(`/job/edit/${jobPostingResponse.id}`);
        }
        setVisible(false);
    };
    return (
        <Wrapper>
            <form onSubmit={handleSubmit}>
                <Grid style={{
                    padding: 'var(--mantine-spacing-md)'
                }}>
                    <Grid.Col span={5}>
                        <CreateJobForm jobPosting={jobPosting} />
                        <Container size="sm" mt={-30}>
                            <Button type="submit">{match ? 'Update Job' : 'Create Job'}</Button>
                        </Container>
                    </Grid.Col>
                    <Grid.Col span={7}>
                        {/* Likely won't get this working for a while but wanted to included it for conceptualization. */}
                        <JobFAQ />
                    </Grid.Col>
                </Grid>
                <Grid style={{
                    padding: 'var(--mantine-spacing-md)'
                }}>
                    <Grid.Col span={12}>
                        <JobDescription
                            description={description}
                            program={programByJobId}
                            visible={visible}
                        />
                    </Grid.Col>
                </Grid>
            </form>
        </Wrapper>
    );
};