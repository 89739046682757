import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home/Home.jsx'
import Login from './pages/Login.jsx'
import Evaluation from './pages/Evaluation.jsx'
import Candidates from './pages/Candidates.jsx'
import CandidateReview from './pages/CandidateReview.jsx'
import Jobs from './pages/Jobs.jsx'
import { UserProvider } from './contexts/UserProvider.jsx'
import PrivateRoutes from './Components/PrivateRoute/PrivateRoutes'
import CandidatesByJob from './pages/CandidatesByJob.jsx'
import AddCandidate from './pages/AddCandidate.jsx'
import RAGTable from './pages/RAGTable.jsx'
import RAGEntry from './pages/RAGEntry.jsx'
import CreateJob from './pages/CreateJob.jsx'
import Request from './pages/Request.jsx'

function App() {
  return (
    <UserProvider>
      <div className="App">
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/request" element={<Request />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Evaluation />} />
            <Route path="/jobs/:id/addcandidate" element={<AddCandidate />} />
            <Route path="/evaluation" element={<Evaluation />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/jobs/create" element={<CreateJob />} />
            <Route path="/jobs/:id" element={<CandidatesByJob />} />
            <Route path="job/edit/:id" element={<CreateJob />} />
            <Route path="/candidates" element={<Candidates />} />
            <Route path="/candidates/:candidateId" element={<CandidateReview />} />
            <Route path="/jobs/:jobId/candidates/:candidateId" element={<CandidateReview />} />
            <Route path="/rag-table" element={<RAGTable />} />
            <Route path="/rag/:id" element={<RAGEntry />} />
          </Route>
        </Routes>
      </div>
    </UserProvider>
  )
}

export default App
