import React from 'react';
import { Tooltip } from '@mantine/core';
import { IconWorld, IconWorldUpload } from '@tabler/icons-react';

export function CompanyWideComment () {

  // api logic for toggling compment goes here

  // placeholder for company wide comment
  const companyWide = false;

  return (
    <Tooltip label={companyWide ? 'This comment has been applied to all jobs company wide' : 'Click to make this comment applicable to all jobs'} position="left" withArrow>
        {companyWide ? <IconWorld size={20} color="green" /> : <IconWorldUpload size={20} />}
    </Tooltip>
  );
};