import React from 'react';
import Wrapper from '../Components/Wrapper/Wrapper';
import { Grid, Button, useMantineTheme } from '@mantine/core';
import { JobsList } from '../Components/JobsList/JobsList.jsx';
import '@mantine/core/styles.css';
import { useNavigate } from 'react-router-dom';

export default function Jobs() {
    const theme = useMantineTheme();
    const navigate = useNavigate();

    return (
        <Wrapper>
            <Grid style={{
                padding: 'var(--mantine-spacing-md)'
            }}>
                <Grid.Col span={8}>
                    <Button
                        style={{
                            position: 'relative',
                            transition: 'background-color 150ms ease',
                            width: 'auto',
                        }}
                        mb={25}
                        fullWidth
                        onClick={() => navigate('/jobs/create')}
                        color={theme.primaryColor}
                    >
                        Create new job
                    </Button>
                    <JobsList />
                </Grid.Col>
            </Grid>
        </Wrapper>
    );
};
