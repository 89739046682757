import { Text, Avatar, Group, TypographyStylesProvider, Paper } from '@mantine/core'
import classes from './CustomerComment.module.css'
import timeAgo from '../../functions/timeAgo.js'
import { CompanyWideComment } from '../CompanyWideComment/CompanyWideComment.js'

export function CustomerComment({ comments }) {
  return (
    <>
      {comments.map((comment, index) => (
        <Paper key={index} withBorder radius="md" style={{ position: 'relative', width: '92%', marginLeft: 'auto' }} className={classes.comment}>
          <Group>
            <Avatar src={comment.createdByAvatar} alt={comment.createdByName === 'OpenAI' ? 'Flex' : comment.createdByName} radius="xl" />
            <div>
              <Text fz="sm">{comment.createdByName === 'OpenAI' ? 'Flex' : comment.createdByName}</Text>
              <Text fz="xs" c="dimmed">
                {timeAgo(comment.createdAt)}
              </Text>
            </div>
            <CompanyWideComment />
          </Group>
          <TypographyStylesProvider className={classes.body}>
            <div className={classes.content} dangerouslySetInnerHTML={{ __html: comment?.body?.message || '' }} />
          </TypographyStylesProvider>
        </Paper>
      ))}
    </>
  )
}
