import React, { useState } from 'react'
import { Group, TextInput, ActionIcon, useMantineTheme, rem } from '@mantine/core'
import { IconArrowRight } from '@tabler/icons-react'
import { createProgramElement } from '../../services/apiProgramElements'
import InteractiveComponentWrapper from '../InteractiveComponentWrapper/InteractiveComponentWrapper'

export function CustomerChatBubble({ programId }) {
  const theme = useMantineTheme()

  const [message, setMessage] = useState('');
  const [messageIsSending, setMessageIsSending] = useState(false);

  const handleSend = async () => {
    if (!message) return;
    if (!programId) return;

    setMessageIsSending(true);

    try {
      const programElements = await createProgramElement({
        programId,
        elementBody: { message },
        type: 'jobdescription'
      });

      console.log(programElements);

      // need to replace this with proper context passing
      window.postMessage({ type: 'programUpdated' }, '*');

    } catch (error) {
      console.error(error);
    }
    setMessage('');
    setMessageIsSending(false);
  }
  const sendButton = (
    <Group justify="center">
      <ActionIcon size={32} radius="xl" color={theme.primaryColor} variant="filled" onClick={handleSend}>
        <IconArrowRight style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
      </ActionIcon>
    </Group>
  );

  return (
    <InteractiveComponentWrapper visible={messageIsSending}>
    <TextInput
      radius="lg"
      size="md"
      mt="md"
      placeholder="Type a message"
      rightSectionWidth={42}
      rightSection={sendButton}
      value={message}
      onChange={event => setMessage(event.target.value)}
    />
    </InteractiveComponentWrapper>
  )
}
