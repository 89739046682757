// apiAuth.js
import store from './store.js'

const GOFLEX_AUTH_ENDPOINT = `${process.env.REACT_APP_GOFLEX_AUTH_ENDPOINT}/oauth/token`
export const GOFLEX_PROGENITOR_ENDPOINT = `${process.env.REACT_APP_GOFLEX_PROGENITOR_ENDPOINT}/progenitor`

export const login = async (username, password) => {
  try {
    const response = await fetch(GOFLEX_AUTH_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username,
        password,
        grantType: 'password'
      })
    })

    const data = await response.json()

    if (!response.ok) {
      const errorMessage = data.errors && data.errors.length > 0 ? data.errors[0] : 'Unknown error';
      throw new Error(`Error authenticating user: ${errorMessage}`);
    }

    if (!data) {
      throw new Error('No tokens returned when authenticating user')
    }

    store.setAuthData(data)

    return data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const refreshAccessToken = async () => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when refreshing access token')
    throw new Error('No auth data found when refreshing access token')
  }

  try {
    const response = await fetch(GOFLEX_AUTH_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        refreshToken: authData.refreshToken,
        grantType: 'refreshToken'
      }),
      headers: { 'Content-Type': 'application/json' }
    })

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error refreshing access token: ${errorMessage}`)
    }

    const tokens = await response.json()

    if (!tokens) {
      throw new Error('No tokens returned when refreshing access token')
    }

    // Set auth data in the store
    store.setAuthData(tokens)

    return true
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
