import { useState } from 'react'
import { Textarea, Container, Group, Button } from '@mantine/core'
import classes from './CustomerFeedback.module.css'
import { reEvaluateCandidate } from '../../services/apiCandidates'

export function CustomerFeedback({ candidate, jobId, setCandidate }) {
  const [value, setValue] = useState('')

  const handleReEvaluate = async () => {
    if (!candidate) {
      console.error('No candidate to re-evaluate')
      return
    }

    const updatedCandidate = await reEvaluateCandidate(candidate.id, jobId)
    setCandidate(updatedCandidate) // Update the candidate in the parent component
  }

  return (
    <Container size="md">
      <Textarea
        label="Please give us your feedback on the candidate or our review of the candidate."
        classNames={classes}
        value={value}
        onChange={event => setValue(event.currentTarget.value)}
        mt="md"
        autoComplete="nope"
        minRows={8}
        maxRows={8}
        size="xl"
        autosize={true}
        styles={{ input: { paddingTop: '30px' } }}
      />
      <Group mt={30}>
        <Button variant="default" radius="xl" size="md" className={classes.control} onClick={handleReEvaluate}>
          Re-Evaluate With Feedback
        </Button>
      </Group>
    </Container>
  )
}
