import React, { useState } from 'react';
import { TextInput, Paper, Container, Button, Text, Box, Group } from '@mantine/core';
import isEmail from 'validator/lib/isEmail';
import classes from './Home/Home.module.css';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';

export default function Request() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        jobTitle: ''
    });

    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.currentTarget;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        setAttemptedSubmit(true);

        if (!isEmail(formData.email)) {
            return;
        }

        const data = new FormData();
        data.append('email', formData.email);
        data.append('jobTitle', formData.jobTitle);

        const sheetURL = 'https://script.google.com/macros/s/AKfycbyaHio0oSsfvqu088ZRDxEqjReCDwwacnmT3NxflTRnBDSlJzrfitTr_DENzdSwIaTSDQ/exec';
        try {
            await fetch(sheetURL, {
                method: 'POST',
                body: data,
                muteHttpExceptions: true,
            });

            setFormData({
                email: '',
                jobTitle: ''
            });

            setFormSubmitted(true);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
        <Box>
            <header className={classes.header}>
                <Group justify="space-between" h="100%" pt={10} pb={10}>
                    <img className={classes.logo} src={logo} alt="Logo" width="70" height="70" />
                    <Group visibleFrom="sm">
                        <Button onClick={() => navigate('/login')}>Sign In</Button>
                    </Group>
                </Group>
            </header>

        </Box>

        <Container size={420} my={40} style={{width: '-webkit-fill-available'}}>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                {formSubmitted ? (
                    <Text align="center" size="xl">
                        Thank you. Someone from our team will follow up with you soon.
                    </Text>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <TextInput
                            label="#1 Job You Are Hiring For"
                            placeholder="e.g. Software Engineer"
                            value={formData.jobTitle}
                            onChange={handleChange}
                            name="jobTitle"
                            required
                        />
                        <TextInput
                            label="Work Email"
                            placeholder="Enter your work email"
                            mt="md"
                            value={formData.email}
                            onChange={handleChange}
                            error={attemptedSubmit && !isEmail(formData.email) ? 'Invalid email address' : null}
                            name="email"
                            required
                        />
                        <Button type="submit" mt="lg" fullWidth>
                            Find out more
                        </Button>
                    </form>
                )}
            </Paper>
        </Container>
        </>
    );
}