import React from 'react';
import { useParams } from 'react-router-dom';
import Wrapper from '../Components/Wrapper/Wrapper';
import { Grid } from '@mantine/core';
import '@mantine/core/styles.css';
import { JobControlTower } from '../Components/JobControlTower/JobControlTower.jsx';

export default function CandidatesByJob() {
    const { id } = useParams();
    return (
        <Wrapper>
            <Grid style={{
                padding: 'var(--mantine-spacing-md)'
            }}>
                <Grid.Col span={12}>
                    <JobControlTower id={id} />
                </Grid.Col>
            </Grid>
    </Wrapper>
    );
};
