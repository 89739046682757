// apiJobPostings.js
import store from './store.js'
import { refreshAccessToken, GOFLEX_PROGENITOR_ENDPOINT } from './apiAuth.js'

let num401Responses = 0

export const getJobPostings = async (params = {}) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when getting job postings')
    throw new Error('No auth data found when getting job postings')
  }

  try {
    const queryString = new URLSearchParams(params).toString()
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/job_postings?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting job postings')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getJobPostings(params)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting job postings: ${errorMessage}`)
    }

    const jobPostings = await response.json()

    if (!jobPostings) {
      throw new Error('No job postings returned when getting job postings')
    }

    // Set job postings data in the store
    store.setJobPostings(jobPostings)

    return jobPostings
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const getJobPostingById = async (jobPostingId) => {
  const authData = store.getAuthData()

  if (!authData) {
    throw new Error('No auth data found when getting job posting by ID')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/job_postings/${jobPostingId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting job posting by ID')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getJobPostingById(jobPostingId)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting job posting by ID: ${errorMessage}`)
    }

    const jobPosting = await response.json()

    if (!jobPosting) {
      return null
    }

    store.upsertJobPosting(jobPosting)

    return jobPosting
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const createJobPosting = async ({ jobTitle, companyName, jobDescription, location, salaryRange }) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when creating a job posting')
    throw new Error('No auth data found when creating a job posting')
  }

  const data = {
    jobTitle,
    companyName,
    jobDescription,
    location,
    salaryRange,
    status: 'Open',
    postedDate: new Date().toISOString().slice(0, 10), // Set postedDate to today's date
  };

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/job_postings`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when creating a job posting')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return createJobPosting(data)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error creating job posting: ${errorMessage}`)
    }

    const jobPosting = await response.json()

    if (!jobPosting) {
      throw new Error('No job posting returned when creating job posting')
    }

    store.addJobPosting(jobPosting)

    return jobPosting
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const updateJobPosting = async (jobPostingId, data) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when updating a job posting')
    throw new Error('No auth data found when updating a job posting')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/job_postings/${jobPostingId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when updating a job posting')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return updateJobPosting(jobPostingId, data)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error updating job posting: ${errorMessage}`)
    }

    const jobPosting = await response.json()

    if (!jobPosting) {
      throw new Error('No job posting returned when updating job posting')
    }

    store.upsertJobPosting(jobPosting)

    return jobPosting
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const deleteJobPosting = async (jobPostingId) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when deleting a job posting')
    throw new Error('No auth data found when deleting a job posting')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/job_postings/${jobPostingId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when deleting a job posting')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return deleteJobPosting(jobPostingId)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error deleting job posting: ${errorMessage}`)
    }

    store.removeJobPosting(jobPostingId)

    return true
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const uploadResumesBulk = async (jobPostingId, files) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when uploading resumes')
    throw new Error('No auth data found when uploading resumes')
  }

  const formData = new FormData()
  files.forEach((file, index) => {
    formData.append(`files[${index}]`, file)
  })

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/job_postings/${jobPostingId}/bulkresumeupload`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when uploading resumes')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return uploadResumesBulk(jobPostingId, files)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error uploading resumes: ${errorMessage}`)
    }

  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
