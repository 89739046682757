import React, { useEffect, useState } from 'react';
import { TextInput, rem, Container, Title } from '@mantine/core';
import classes from './CreateJobForm.module.css';

export default function CreateJobForm({ jobPosting }) {
    const [jobTitle, setJobTitle] = useState('');
    const [location, setLocation] = useState('');
    const [salaryRange, setSalaryRange] = useState('');

    useEffect(() => {
        if (jobPosting) {
            setJobTitle(jobPosting.jobTitle ?? '');
            setLocation(jobPosting.location ?? '');
            setSalaryRange(jobPosting.salaryRange ?? '');
        }
    }, [jobPosting]);

    const styles = {
        root: {position: 'relative', marginBottom: rem('24px')},
        label: {
            position: 'absolute',
            pointerEvents: 'none',
            fontSize: 'var(--mantine-font-size-xs)',
            paddingLeft: 'var(--mantine-spacing-sm)',
            paddingTop: 'calc(var(--mantine-spacing-sm) / 2)',
            zIndex: 1,
        },
        input: {height: rem('54px'), paddingTop: rem('18px')},
    }

    return (
        <Container size="sm" className={classes.wrapper}>
            <Title size="h2" ta="left" className={classes.title}>
                Create Job Description
            </Title>
            <TextInput
                name="jobTitle"
                label="Job Title"
                placeholder="e.g. Account Executive"
                styles={styles}
                value={jobTitle}
                onChange={(event) => setJobTitle(event.currentTarget.value)}
            />
            <TextInput
                name="location"
                label="Location"
                placeholder="New York, NY"
                styles={styles}
                value={location}
                onChange={(event) => setLocation(event.currentTarget.value)}
            />
            <TextInput
                name="salaryRange"
                label="Salary Range"
                placeholder="$100,000 - $120,000"
                styles={styles}
                value={salaryRange}
                onChange={(event) => setSalaryRange(event.currentTarget.value)}
            />
        </Container>
    );
}