import { useEffect, useState } from 'react';
import { Container, Title, Button, Group, Text, List, ThemeIcon, rem, GridCol, Grid, Switch } from '@mantine/core'
import { IconCheck, IconX } from '@tabler/icons-react'
import classes from './CandidateSnapshot.module.css'
import { getProgramSummary } from '../../services/apiPrograms'

export function CandidateSnapshot({ programId, jobPostingId }) {
  const [summary, setSummary] = useState(null);
  const [evaluation, setEvaluation] = useState(null);
  const [evaluationReason, setEvaluationReason] = useState(null);
  const [proPoints, setProPoints] = useState([]);
  const [conPoints, setConPoints] = useState([]);
  const [evaluateWithFlex, setEvaluateWithFlex] = useState(false);

  const evalFlexHandler = (checked) => {
    if (checked) {
      setSummary(null);
      setEvaluation(null);
      setEvaluationReason(null);
      setProPoints([]);
      setConPoints([]);
    }
    setEvaluateWithFlex(checked);
  }

  useEffect(() => {
    const fetchSummary = async () => {
      if (!programId || !jobPostingId) {
        return;
      }

      const data = await getProgramSummary(programId, jobPostingId, evaluateWithFlex);
      setSummary(data.cvSummary);
      setEvaluation(data.cvEvaluation);
      setEvaluationReason(data.cvEvaluationReason);
      setProPoints(data.cvProPoints);
      setConPoints(data.cvConPoints);
    };

    fetchSummary();
  }, [programId, jobPostingId, evaluateWithFlex]);

  return (
    <Container size="md" style={{width: '100%'}}>
      <div className={`${classes.inner} fullWidth`}>
        <div className={`${classes.content} fullWidth`}>
          <Title className={classes.title}>
            {evaluation !== null ? `${Math.floor(evaluation)}% fit` : 'Loading...'}
            {/* toggle fit source */}
            {window.location.pathname.startsWith('/evaluation') && (

            <Group justify="left" p="md" style={{float: 'right'}}>
              <Switch
                label="Evaluate with Flex"
                classNames={`${classes.body} ${classes.track} ${classes.thumb}`}
                checked={evaluateWithFlex}
                onChange={(event) => evalFlexHandler(event.currentTarget.checked)}
              />
            </Group>
            )}
            <br />
          </Title>
          <Text c="dimmed" mt="md">
            {evaluationReason || 'Loading...'}
          </Text>
          <Title order={4} mt="md">Summary</Title>
          <Text c="dimmed" mt="md">
            {summary || 'Loading...'}
          </Text>

          <Grid gutter="md" mt={30}>
            <GridCol span={6}>
              <List
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl">
                    <IconCheck style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                  </ThemeIcon>
                }
              >
                {proPoints.map((point, index) => (
                  <List.Item key={index}>{point}</List.Item>
                ))}
              </List>
            </GridCol>
            <GridCol span={6}>
              <List
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl" color="red">
                    <IconX style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                  </ThemeIcon>
                }
              >
                {conPoints.map((point, index) => (
                  <List.Item key={index}>{point}</List.Item>
                ))}
              </List>
            </GridCol>
          </Grid>

          <Group mt={30}>
            <Button radius="xl" size="md" className={classes.control}>
              Interview
            </Button>
            <Button variant="default" radius="xl" size="md" className={classes.control}>
              Screen
            </Button>
          </Group>
        </div>
      </div>
    </Container>
  )
}
