import { Container, Title, Accordion } from '@mantine/core';
import classes from './JobFAQ.module.css';
import { CustomerChatBubble } from '../CustomerChatBubble/CustomerChatBubble';

const experienceAnswer = 'It depends what salary you are targeting for the role. If you have a lower salary, it might be better to list fewer years of experience because you may attract a rising start';
const niceToHaveAnswer = 'It is not recommended as this has shown to decrease the number of female applicants.';
const quotalAnswer = 'Yes, you can write that. However, many candidates are coming from companies where quota attainment was impossible and that is why they are looking for something new. This is better addressed in pre-screening questions or an interview.';

export default function CreateJobFAQ() {
  return (
    <Container size="sm" className={classes.wrapper}>
      <Title size="h2" ta="left" className={classes.title}>
        Frequently Asked Questions
      </Title>

      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="reset-password">
          <Accordion.Control>How many years of experience should I require?</Accordion.Control>
          <Accordion.Panel>
            {experienceAnswer}
            <CustomerChatBubble />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="another-account">
          <Accordion.Control>Should I have a Nice To Have section?</Accordion.Control>
          <Accordion.Panel>
            {niceToHaveAnswer}
            <CustomerChatBubble />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="newsletter">
          <Accordion.Control>Can I write that we are looking for people with a track record of quota attainment?</Accordion.Control>
          <Accordion.Panel>
            {quotalAnswer}
            <CustomerChatBubble />
          </Accordion.Panel>
        </Accordion.Item>

      </Accordion>
    </Container>
  );
}