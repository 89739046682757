import React from 'react';

export function Dots({ size = 185, radius = 2.5, ...rest }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 185 185"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            {Array.from({ length: 9 }, (_, i) => (
                <React.Fragment key={i}>
                    {Array.from({ length: 9 }, (_, j) => (
                        <rect
                            key={j}
                            x={20 * i}
                            y={20 * j}
                            width={5}
                            height={5}
                            rx={radius}
                            fill="currentColor"
                        />
                    ))}
                </React.Fragment>
            ))}
        </svg>
    );
}