import { useState } from 'react'
import { Center, Tooltip, UnstyledButton, Stack, rem } from '@mantine/core'
import { IconDeviceDesktopAnalytics, IconFingerprint, IconCalendarStats, IconSettings, IconLogout, IconSwitchHorizontal, IconFileDescription, IconAward, IconRadar, IconArrowLoopRight } from '@tabler/icons-react'
import classes from './Navbar.module.css'
import PropTypes from 'prop-types'

import logo from '../../assets/logo.png'
import { Link } from 'react-router-dom'

function NavbarLink({ icon: Icon, label, active, href, onClick }) {
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton onClick={onClick} className={classes.link} data-active={active || undefined}>
        <Link to={href} className={classes.link} data-active={active || undefined}>
          <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
        </Link>
      </UnstyledButton>
    </Tooltip>
  )
}

NavbarLink.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func
}

const linkdata = [
  { icon: IconAward, label: 'Evaluation', href: '/evaluation' },
  { icon: IconCalendarStats, label: 'Interviews', href: '/interviews' },
  { icon: IconFileDescription, label: 'Jobs', href: '/jobs' },
  { icon: IconRadar, label: 'Find Candidates', href: '/candidates' },
  { icon: IconDeviceDesktopAnalytics, label: 'Analytics', href: '/analytics' },
  { icon: IconFingerprint, label: 'Security', href: '/security' },
  { icon: IconArrowLoopRight, label: 'RAG', href: '/rag-table' },
  { icon: IconSettings, label: 'Settings', href: '/settings' }
]

export function Navbar() {
  const [active, setActive] = useState()

  const links = linkdata.map((link, index) => <NavbarLink {...link} key={link.label} active={index === active} onClick={() => setActive(index)} />)

  return (
    <nav className={classes.navbar}>
      <Center>
        <img src={logo} alt="Logo" width="50" height="50" />
      </Center>

      <div className={classes.navbarMain}>
        <Stack justify="center" gap={0}>
          {links}
        </Stack>
      </div>

      <Stack justify="center" gap={0}>
        <NavbarLink icon={IconSwitchHorizontal} label="Change account" />
        <NavbarLink icon={IconLogout} label="Logout" />
      </Stack>
    </nav>
  )
}
