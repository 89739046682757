import { useEffect, useState } from 'react';
import { Container, Title, Button, Group, Text, List, ThemeIcon, rem, GridCol, Grid } from '@mantine/core'
import { IconCheck, IconX } from '@tabler/icons-react'
import classes from './CustomerCandidateSnapshot.module.css'

export function CustomerCandidateSnapshot({ candidate }) {
  const [summary, setSummary] = useState(null);
  const [evaluation, setEvaluation] = useState(null);
  const [evaluationReason, setEvaluationReason] = useState(null);
  const [proPoints, setProPoints] = useState([]);
  const [conPoints, setConPoints] = useState([]);

  useEffect(() => {
    if (!candidate || !candidate.resumes || candidate.resumes.length === 0) {
      return;
    }

    const resume = candidate.resumes[0];
    setSummary(resume.evaluationSummary);
    setEvaluation(resume.evaluationScore);
    setEvaluationReason(resume.evaluationReason);
    setProPoints(resume.evaluationPositivePoints || []);
    setConPoints(resume.evaluationNegativePoints || []);
  }, [candidate,]);

  return (
    <Container size="md" style={{width: '100%'}}>
      <div className={`${classes.inner} fullWidth`}>
        <div className={`${classes.content} fullWidth`}>
          <Title className={classes.title}>
            {evaluation !== null ? `${Math.floor(evaluation)}% fit` : 'Loading...'}
            <br />
          </Title>
          <Text c="dimmed" mt="md">
            {evaluationReason || 'Loading...'}
          </Text>
          <Title order={4} mt="md">Summary</Title>
          <Text c="dimmed" mt="md">
            {summary || 'Loading...'}
          </Text>

          <Grid gutter="md" mt={30}>
            <GridCol span={6}>
              <List
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl">
                    <IconCheck style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                  </ThemeIcon>
                }
              >
                {proPoints.map((point, index) => (
                  <List.Item key={index}>{point}</List.Item>
                ))}
              </List>
            </GridCol>
            <GridCol span={6}>
              <List
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl" color="red">
                    <IconX style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                  </ThemeIcon>
                }
              >
                {conPoints.map((point, index) => (
                  <List.Item key={index}>{point}</List.Item>
                ))}
              </List>
            </GridCol>
          </Grid>

          <Group mt={30}>
            <Button radius="xl" size="md" className={classes.control}>
              Interview
            </Button>
            <Button variant="default" radius="xl" size="md" className={classes.control}>
              Screen
            </Button>
          </Group>
        </div>
      </div>
    </Container>
  )
}
