import cx from 'clsx';
import { useState } from 'react'
import {
  Table,
  Checkbox,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  Tabs,
  Container,
  Space,
  Button
} from '@mantine/core'
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconTrash } from '@tabler/icons-react'
import classes from './RAGList.module.css'
import { useNavigate } from 'react-router-dom'

const tabs = [
  'OpenAI',
  'OpenAI with RAG',
  'Google AI',
  'Google AI with RAG',
];

function Th({ children, reversed, sorted, onSort }) {
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector

  return (
    <Table.Th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  )
}

function filterData(data, query) {
  if (!data.length) {
    return []
  }

  const keys = Object.keys(data[0])

  return data.filter(item => keys.some(key => String(item[key]).toLowerCase().includes(query)))
}

function sortData(data, payload) {
  const { sortBy } = payload

  if (!sortBy) {
    return filterData(data, payload.search)
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy])
      }

      return a[sortBy].localeCompare(b[sortBy])
    }),
    payload.search
  )
}
const data = [
  {
    id: 1,
    context: 'Google - September 2021 to Present',
    response: 'Google hires good developers',
    company: 'Chemsyn Services',
  },
  {
    id: 2,
    context: 'Microsoft - August 2021 to Present',
    response: 'Microsoft codes in C#',
    company: 'Chemsyn Services',
  },
  {
    id: 3,
    context: 'Amazon - July 2021 to Present',
    response: 'Amazon focuses on customer service',
    company: 'Phoenix Technologies',
  },
  {
    id: 4,
    context: 'Facebook - June 2021 to Present',
    response: 'Facebook values user engagement',
    company: 'Chemsyn Services',
  },
  {
    id: 5,
    context: 'Apple - May 2021 to Present',
    response: 'Apple prioritizes design and user experience',
    company: 'Chemsyn Services',
  },
]
export function RAGList (id) {
  // const queryId = id ? id : 'all';
  const [search, setSearch] = useState('')
  const [sortedData, setSortedData] = useState(data || [])
  const [sortBy, setSortBy] = useState(null)
  const [reverseSortDirection, setReverseSortDirection] = useState(false)
  const [selection, setSelection] = useState(['1']);
  const navigate = useNavigate()

  const toggleRow = (id) =>
    setSelection((current) =>
      current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
  );
  const toggleAll = () =>
    setSelection((current) => (current.length === data.length ? [] : data.map((item) => item.id)));

  const setSorting = field => {
    const reversed = field === sortBy ? !reverseSortDirection : false
    setReverseSortDirection(reversed)
    setSortBy(field)
    const sortedData = sortData(data, { sortBy: field, reversed, search })
    setSortedData(sortedData)
  }

  const handleSearchChange = event => {
    const { value } = event.currentTarget
    setSearch(value)
    const sortedData = sortData(data, { sortBy, reversed: reverseSortDirection, search: value })
    setSortedData(sortedData)
  }

  // construct the header menu tabs
  const items = tabs.map((tab) => (
    <Tabs.Tab value={tab} key={tab}>
      {tab}
    </Tabs.Tab>
  ));

  const rows = sortedData.map((row) => {
    const selected = selection.includes(row.id);
    return (
        <Table.Tr
          key={row.id}
          style={{ cursor: 'pointer' }}
          className={cx({ [classes.rowSelected]: selected })}
        >
          <Table.Td style={{width: rem(100)}}>
            <Checkbox checked={selection.includes(row.id)} onChange={() => toggleRow(row.id)} />
          </Table.Td>
            <Table.Td><div onClick={() => navigate(`/rag/${row.id}`)}>{row.context}</div></Table.Td>
            <Table.Td><div onClick={() => navigate(`/rag/${row.id}`)}>{row.response}</div></Table.Td>
            <Table.Td><div onClick={() => navigate(`/rag/${row.id}`)}>{row.company}</div></Table.Td>
        </Table.Tr>
    )
  })

  return (
    <>
      <div className={classes.header}>
      <Container className={classes.mainSection} size="md" style={{ marginLeft: rem(16), display: 'flex', justifyContent: 'space-between' }}>
        <Text fw={500} size="lg" lh={1} mr={3}>
          RAG List
        </Text>
        <Button
          variant='outline'
          rightSection={<IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          size='md'
          radius='md'
          styles={{
            root: { paddingRight: rem(14), height: rem(48) },
            section: { marginLeft: rem(22) },
          }}
        >
          Delete Selected
        </Button>
      </Container>
      <Container size="md" style={{marginLeft: '0'}}>
        <Tabs
          defaultValue={tabs[0].value}
          variant="outline"
          visibleFrom="sm"
          classNames={{
            root: classes.tabs,
            list: classes.tabsList,
            tab: classes.tab,
          }}
        >
          <Tabs.List>{items}</Tabs.List>
        </Tabs>
      </Container>
    </div>
    <Space h="xl" />
    <ScrollArea>
      <TextInput placeholder="Search by any field" mb="md" leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />} value={search} onChange={handleSearchChange} />
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw={700}
      >
        <Table.Tbody>
          <Table.Tr>
            <Th>
              <Checkbox
                onChange={toggleAll}
                checked={selection.length === data.length}
                indeterminate={selection.length > 0 && selection.length !== data.length}
              />
            </Th>
            <Th sorted={sortBy === 'context'} reversed={reverseSortDirection} onSort={() => setSorting('context')}>
              Context
            </Th>
            <Th sorted={sortBy === 'response'} reversed={reverseSortDirection} onSort={() => setSorting('response')}>
              Response
            </Th>
            <Th sorted={sortBy === 'company'} reversed={reverseSortDirection} onSort={() => setSorting('company')}>
              Company
            </Th>
          </Table.Tr>
        </Table.Tbody>
        <Table.Tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <Table.Tr>
              <Table.Td colSpan={Object.keys(data[0]).length}>
                <Text fw={500} ta="center">
                  Nothing found
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </ScrollArea>
    </>
  )
}
