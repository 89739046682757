import React from 'react'
import { Box, LoadingOverlay } from '@mantine/core'

const InteractiveComponentWrapper = ({ visible, children }) => (
    <Box pos="relative">
        <LoadingOverlay id='test' visible={visible} zIndex={1000} overlayProps={{ radius: 'sm', blur: 2}} loaderProps={{ color: 'purple', type: 'bars' }} />
        {children}
    </Box>
)

export default InteractiveComponentWrapper
