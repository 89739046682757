import React, { useState, useEffect } from 'react';
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import classes from './JobsList.module.css';
import { useNavigate }  from 'react-router-dom';
import { getJobPostings } from '../../services/apiJobPostings';

function Th({ children, reversed, sorted, onSort }) {
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;

    return (
      <Table.Th className={classes.th}>
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group justify="space-between">
            <Text fw={500} fz="sm">
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
            </Center>
          </Group>
        </UnstyledButton>
      </Table.Th>
    );
}

function filterData(data, query) {
  if (!data.length) {
    return [];
  }

  const keys = Object.keys(data[0]);
  const lowerCaseQuery = query.toLowerCase();

  return data.filter((item) =>
    keys.some((key) => String(item[key]).toLowerCase().includes(lowerCaseQuery))
  );
}

function sortData(data, payload) {
  const { sortBy } = payload;

  if (!data || data.length === 0) {
    return [];
  }

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search
  );
}

export function JobsList() {

  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [initialData, setInitialData] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      const response = await getJobPostings();
      setInitialData(response);
      setSortedData(response);
    }
    fetchJobs();
  }, []);

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    const newSortedData = sortData(initialData, { sortBy: field, reversed, search });
    setSortedData(newSortedData);
  };

const handleSearchChange = (event) => {
  const { value } = event.currentTarget;
  setSearch(value);
  const newSortedData = sortData(initialData, { sortBy, reversed: reverseSortDirection, search: value });
  setSortedData(newSortedData);
};

const rows = sortedData.map((row) => (
  <Table.Tr
    key={row.id}
    onClick={() => navigate(`/jobs/${row.id}`)}
    style={{ cursor: 'pointer' }}
  >
    <Table.Td>{row.jobTitle}</Table.Td>
  </Table.Tr>
));

  return (
    <ScrollArea>
      <TextInput
        placeholder="Search by any field"
        mb="md"
        leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
        value={search}
        onChange={handleSearchChange}
      />
      <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed">
        <Table.Tbody>
          <Table.Tr>
            <Th
              sorted={sortBy === 'jobTitle'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('jobTitle')}
            >
              Title
            </Th>
          </Table.Tr>
        </Table.Tbody>
        <Table.Tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <Table.Tr>
              <Table.Td colSpan={sortedData[0] ? Object.keys(sortedData[0]).length : 0}>
                <Text fw={500} ta="center">
                  Nothing found
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
}
