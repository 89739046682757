import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mantine/core';
import '@mantine/core/styles.css';
import ResumeCustomerView from '../Components/ResumeCustomerView/ResumeCustomerView';
import { NAVBAR_WIDTH } from '../constants';
import { CustomerCandidateSnapshot } from '../Components/CustomerCandidateSnapshot/CustomerCandidateSnapshot';
import { CustomerFeedback } from '../Components/CustomerFeedback/CustomerFeedback.jsx';
import { getCandidateById } from '../services/apiCandidates';

export default function CandidateReview() {
  const { jobId, candidateId } = useParams();
  const [candidate, setCandidate] = useState(null);

  useEffect(() => {
    const fetchCandidate = async () => {
      const options = jobId ? { job_posting_id: jobId } : {};
      const fetchedCandidate = await getCandidateById(candidateId, options);
      setCandidate(fetchedCandidate);
    };

    fetchCandidate();
  }, [candidateId, jobId]);

  return (
    <div style={{
        display: 'flex',
        '--navbar-width': NAVBAR_WIDTH
      }}>
        <div style={{
          marginLeft: 'var(--navbar-width)',
          width: 'calc(100vw - var(--navbar-width))',
          height: '100vh'
        }}>
          <Grid style={{
            padding: 'var(--mantine-spacing-md)'
          }}>
            <Grid.Col span={6}>
              <CustomerCandidateSnapshot candidate={candidate} />
            </Grid.Col>
            <Grid.Col span={6}>
              {jobId && <CustomerFeedback candidate={candidate} jobId={jobId} setCandidate={setCandidate} />}
            </Grid.Col>
          </Grid>
         <ResumeCustomerView candidate={candidate} />
      </div>
    </div>
  );
}
