// UserProvider.jsx
import React, { createContext, useState, useEffect } from 'react'
import store from '../services/store.js'

export const UserContext = createContext()

export function UserProvider({ children }) {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const authData = store.getAuthData()
    if (authData.accessToken) {
      setUser(authData)
    }
    setLoading(false)
  }, [])

  const setUserData = (data) => {
    store.setAuthData(data)
    setUser(data)
  }

  return <UserContext.Provider value={{ user, setUser: setUserData, loading }}>{children}</UserContext.Provider>
}
