import React, { useState } from 'react';
import { rem, FileInput, Button } from '@mantine/core';
import { IconFileCv } from '@tabler/icons-react';
import { uploadResumesBulk } from '../../services/apiJobPostings';

export function AddCandidateForm({ id }) {
    const styles = {
    root: {position: 'relative', marginBottom: rem('24px')},
    label: {
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: 'var(--mantine-font-size-xs)',
        paddingLeft: 'var(--mantine-spacing-sm)',
        paddingTop: 'calc(var(--mantine-spacing-sm) / 2)',
        zIndex: 1,
    },
    input: {height: rem('54px'), paddingTop: rem('18px')},
  }
  const icon = <IconFileCv style={{ width: rem(18), height: rem(18), marginTop: rem(10) }} stroke={1.5} />;

  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);

  const handleFileChange = (selectedFiles) => {
    setFiles(Array.from(selectedFiles));
  };

  const [message, setMessage] = useState(''); // add this line
  const handleUpload = async () => {
    setLoading(true);
    try {
      await uploadResumesBulk(id, files);
      setMessage('Resumes uploaded successfully'); // update this line
    } catch (error) {
      setMessage('Error uploading resumes'); // update this line
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p>{message}</p>
      <FileInput
        leftSection={icon}
        label="Attach your CV"
        placeholder="Your CV"
        leftSectionPointerEvents="none"
        styles={styles}
        multiple
        onChange={handleFileChange} // add this line
      />
      <Button
        onClick={handleUpload} // add this line
        loading={loading}
        loaderProps={{ type: 'dots' }}
      >
        Add Candidate
      </Button>
    </>
  );
}
