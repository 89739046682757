import React from 'react';
import { useParams } from 'react-router-dom';
import Wrapper from '../Components/Wrapper/Wrapper';
import { Grid } from '@mantine/core';
import '@mantine/core/styles.css';
import { AddCandidateForm } from '../Components/AddCandidateForm/AddCandidateForm.jsx';

export default function AddCandidate() {
    const { id } = useParams();
    return (
        <Wrapper>
            <Grid style={{
                padding: 'var(--mantine-spacing-md)'
            }}>
                <Grid.Col span={10}>
                    <h2>Add Candidate</h2>
                    <AddCandidateForm id={id} />
                </Grid.Col>
            </Grid>
    </Wrapper>
    );
};
