// apiPrograms.js
import store from './store.js'
import { refreshAccessToken, GOFLEX_PROGENITOR_ENDPOINT } from './apiAuth.js'

let num401Responses = 0

export const getPrograms = async route => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when getting programs')
    throw new Error('No auth data found when getting programs')
  }

  try {
    const queryString = new URLSearchParams({ route }).toString()
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/programs?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting programs')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getPrograms({ route })
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting programs: ${errorMessage}`)
    }

    const programs = await response.json()

    if (!programs) {
      throw new Error('No programs returned when getting programs')
    }

    // Set programs data in the store
    store.setPrograms(programs)

    return programs
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const getProgramById = async programId => {
  const authData = store.getAuthData()

  if (!authData) {
    throw new Error('No auth data found when getting program by ID')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/programs/${programId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting program by ID')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getProgramById(programId)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting program by ID: ${errorMessage}`)
    }

    const program = await response.json()

    if (!program) {
      return null
    }

    store.upsertProgram(program)

    return program
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const getProgramSummary = async (programId, jobPostingId, evaluationWithFlex) => {
  const authData = store.getAuthData()

  if (!authData) {
    throw new Error('No auth data found when getting program summary')
  }

  const queryString = `?evaluationWithFlex=${evaluationWithFlex}`;
  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/programs/${programId}/get_summary/job_postings/${jobPostingId}${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      },
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting program summary')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getProgramSummary(programId)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting program summary: ${errorMessage}`)
    }

    const summary = await response.json()

    if (!summary) {
      return null
    }

    return summary
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const getProgramAutocomplete = async (name, route) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when getting program autocomplete')
    throw new Error('No auth data found when getting program autocomplete')
  }

  try {
    const queryString = new URLSearchParams({ name, route }).toString()
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/programs/autocomplete?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting program autocomplete')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getProgramAutocomplete(name, route)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting program autocomplete: ${errorMessage}`)
    }

    const autocompleteResults = await response.json()

    if (!autocompleteResults) {
      throw new Error('No autocomplete results returned when getting program autocomplete')
    }

    return autocompleteResults
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const createProgram = async ({ programName, locations, contextText, documentText, jobPostingId, resumeId }) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when creating a program')
    throw new Error('No auth data found when creating a program')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/programs`, {
      method: 'POST',
      body: JSON.stringify({
        name: programName,
        locations,
        contextText,
        documentText,
        jobPostingId,
        resumeId
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when creating a program')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return createProgram({ programName, locations, contextText, documentText, jobPostingId, resumeId})
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error creating program: ${errorMessage}`)
    }

    const program = await response.json()

    if (!program) {
      throw new Error('No program returned when creating program')
    }

    store.addProgram(program)

    return program
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const updateProgram = async ({ id, name, locations, contextText, documentText, jobPostingId, resumeId }) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when updating a program')
    throw new Error('No auth data found when updating a program')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/programs/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        name,
        locations,
        contextText,
        documentText,
        jobPostingId,
        resumeId
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when updating a program')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return updateProgram({ id, name, locations, contextText, documentText, jobPostingId, resumeId })
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error updating program: ${errorMessage}`)
    }

    const program = await response.json()

    if (!program) {
      throw new Error('No program returned when updating program')
    }

    store.upsertProgram(program)

    return program
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const getProgramsByJobId = async jobId => {
  const authData = store.getAuthData()

  if (!authData) {
    throw new Error('No auth data found when getting programs by job ID')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/programs/job_postings/${jobId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting programs by job ID')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getProgramsByJobId({ jobId })
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting programs by job ID: ${errorMessage}`)
    }

    const programs = await response.json()

    if (!programs) {
      throw new Error('No programs returned when getting programs by job ID')
    }

    return programs
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const getProgramsByResumeId = async resumeId => {
  const authData = store.getAuthData()

  if (!authData) {
    throw new Error('No auth data found when getting programs by resume ID')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/programs/resumes/${resumeId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting programs by resume ID')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getProgramsByResumeId({ resumeId })
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting programs by resume ID: ${errorMessage}`)
    }

    const programs = await response.json()

    if (!programs) {
      throw new Error('No programs returned when getting programs by resume ID')
    }

    return programs
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
