import React from 'react';
import Wrapper from '../Components/Wrapper/Wrapper';
import { Grid } from '@mantine/core';
import '@mantine/core/styles.css';
import { CandidateList } from '../Components/CandidateList/CandidateList';

export default function Candidates() {
    return (
        <Wrapper>
            <Grid style={{
                padding: 'var(--mantine-spacing-md)'
            }}>
                <Grid.Col span={12}>
                    <CandidateList id={'all'} />
                </Grid.Col>
            </Grid>
    </Wrapper>
    );
};
