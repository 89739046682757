import Cookies from 'js-cookie';

class Store {
  constructor() {
    this.processedSelectors = new Set()
  }

  setAuthData(authData) {
    Cookies.set('accessToken', authData.accessToken, { expires: authData.expiresIn / 60 / 24, sameSite: 'None', secure: true })
    Cookies.set('refreshToken', authData.refreshToken, { expires: 14, sameSite: 'None', secure: true })
    Cookies.set('tokenType', authData.tokenType, { expires: 14, sameSite: 'None', secure: true })
  }

  getAuthData() {
    const accessToken = Cookies.get('accessToken')
    const refreshToken = Cookies.get('refreshToken')
    const tokenType = Cookies.get('tokenType')

    return { accessToken, refreshToken, tokenType }
  }

  clearAuthData() {
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')
    Cookies.remove('tokenType')
  }

  setPrograms(programs) {
    window.localStorage.setItem('programs', JSON.stringify(programs))
  }

  getPrograms() {
    const programs = JSON.parse(localStorage.getItem('programs'))
    return programs || []
  }

  getProgramById(programId) {
    const programs = this.getPrograms()
    return programs.find(p => p.id === programId)
  }

  getProgramByElementId(elementId) {
    const programs = this.getPrograms()
    return programs.find(program => program.elements.some(element => element.id === elementId))
  }

  getProgramBySelector(selector) {
    const programs = this.getPrograms()
    const existingProgram = programs.find(program => {
      if (!program.locations) {
        return false
      }
      return program.locations.some(location => {
        return selector === location.selector
      })
    })
    return existingProgram || null
  }

  addProgram(program) {
    const programs = this.getPrograms()
    programs.push(program)
    this.setPrograms(programs)
  }

  updateProgram(program) {
    const programs = this.getPrograms()
    const index = programs.findIndex(p => p.id === program.id)
    programs[index] = program
    this.setPrograms(programs)
  }

  upsertProgram(program) {
    const programs = this.getPrograms()
    const existingProgramIndex = programs.findIndex(p => p.id === program.id)
    if (existingProgramIndex !== -1) {
      programs[existingProgramIndex] = program
    } else {
      programs.push(program)
    }
    this.setPrograms(programs)
  }

  clearPrograms() {
    window.localStorage.removeItem('programs')
  }

  setJobPostings(jobPostings) {
    window.localStorage.setItem('jobPostings', JSON.stringify(jobPostings))
  }

  getJobPostings() {
    const jobPostings = JSON.parse(localStorage.getItem('jobPostings'))
    return jobPostings || []
  }

  getJobPostingById(jobPostingId) {
    const jobPostings = this.getJobPostings()
    return jobPostings.find(jp => jp.id === jobPostingId)
  }

  addJobPosting(jobPosting) {
    const jobPostings = this.getJobPostings()
    jobPostings.push(jobPosting)
    this.setJobPostings(jobPostings)
  }

  updateJobPosting(jobPosting) {
    const jobPostings = this.getJobPostings()
    const index = jobPostings.findIndex(jp => jp.id === jobPosting.id)
    jobPostings[index] = jobPosting
    this.setJobPostings(jobPostings)
  }

  upsertJobPosting(jobPosting) {
    const jobPostings = this.getJobPostings()
    const existingJobPostingIndex = jobPostings.findIndex(jp => jp.id === jobPosting.id)
    if (existingJobPostingIndex !== -1) {
      jobPostings[existingJobPostingIndex] = jobPosting
    } else {
      jobPostings.push(jobPosting)
    }
    this.setJobPostings(jobPostings)
  }

  removeJobPosting(jobPostingId) {
    const jobPostings = this.getJobPostings().filter(jp => jp.id !== jobPostingId)
    this.setJobPostings(jobPostings)
  }

  setCandidates(candidates) {
    window.localStorage.setItem('candidates', JSON.stringify(candidates))
  }

  getCandidates() {
    const candidates = JSON.parse(localStorage.getItem('candidates'))
    return candidates || []
  }

  getCandidateById(candidateId) {
    const candidates = this.getCandidates()
    return candidates.find(c => c.id === candidateId)
  }

  addCandidate(candidate) {
    const candidates = this.getCandidates()
    candidates.push(candidate)
    this.setCandidates(candidates)
  }

  updateCandidate(candidate) {
    const candidates = this.getCandidates()
    const index = candidates.findIndex(c => c.id === candidate.id)
    candidates[index] = candidate
    this.setCandidates(candidates)
  }

  upsertCandidate(candidate) {
    const candidates = this.getCandidates()
    const existingCandidateIndex = candidates.findIndex(c => c.id === candidate.id)
    if (existingCandidateIndex !== -1) {
      candidates[existingCandidateIndex] = candidate
    } else {
      candidates.push(candidate)
    }
    this.setCandidates(candidates)
  }

  removeCandidate(candidateId) {
    const candidates = this.getCandidates().filter(c => c.id !== candidateId)
    this.setCandidates(candidates)
  }

  setResumes(resumes) {
    window.localStorage.setItem('resumes', JSON.stringify(resumes))
  }

  getResumes() {
    const resumes = JSON.parse(localStorage.getItem('resumes'))
    return resumes || []
  }

  getResumeById(resumeId) {
    const resumes = this.getResumes()
    return resumes.find(r => r.id === resumeId)
  }

  addResume(resume) {
    const resumes = this.getResumes()
    resumes.push(resume)
    this.setResumes(resumes)
  }

  updateResume(resume) {
    const resumes = this.getResumes()
    const index = resumes.findIndex(r => r.id === resume.id)
    resumes[index] = resume
    this.setResumes(resumes)
  }

  upsertResume(resume) {
    const resumes = this.getResumes()
    const existingResumeIndex = resumes.findIndex(r => r.id === resume.id)
    if (existingResumeIndex !== -1) {
      resumes[existingResumeIndex] = resume
    } else {
      resumes.push(resume)
    }
    this.setResumes(resumes)
  }

  deleteResume(resumeId) {
    const resumes = this.getResumes().filter(r => r.id !== resumeId)
    this.setResumes(resumes)
  }

}

export default new Store()
