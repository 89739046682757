import React, { useEffect, useState } from 'react';
import { Grid, Select } from '@mantine/core';
import '@mantine/core/styles.css';
import Resume from '../Components/Resume/Resume';
import { NAVBAR_WIDTH } from '../constants';
import { CandidateSnapshot } from '../Components/CandidateSnapshot/CandidateSnapshot';
import { Questions } from '../Components/Questions/Questions';
import { getPrograms } from '../services/apiPrograms';
import { getJobPostings } from '../services/apiJobPostings';

export default function Evaluation() {
  const [programId, setProgramId] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  // const iframeSrc = '/ValPorterWithoutGoogle.html';
  const iframeSrc = '/DenizCan3.html';

  useEffect(() => {
    const fetchPrograms = async () => {
      const programs = await getPrograms(iframeSrc);
      if (programs.length > 0) {
        setProgramId(programs[0].id);
      }
    };

    const fetchJobs = async () => {
      const jobPostings = await getJobPostings();
      setJobs(jobPostings);
    }

    fetchPrograms();
    fetchJobs();
  }, []);

  const handleJobSelect = (job) => {
    setSelectedJob(job);
  }

  return (
    <div style={{
        display: 'flex',
        '--navbar-width': NAVBAR_WIDTH
      }}>
        <div style={{
          marginLeft: 'var(--navbar-width)',
          width: 'calc(100vw - var(--navbar-width))',
          height: '100vh'
        }}>
          <Grid style={{
            padding: 'var(--mantine-spacing-md)'
          }}>
            <Grid.Col span={6}>
              <Select
                data={jobs.map(job => ({ value: job.id, label: job.jobTitle }))} // map jobs to Select data format
                placeholder="Select a job"
                onChange={handleJobSelect}
              />

              <CandidateSnapshot programId={programId} jobPostingId={selectedJob} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Questions questions={['What excited you about this role?', 'Have you worked with any CRMs before?', 'What is the largest deal you\'ve closed?']}/>
            </Grid.Col>
          </Grid>
          <Resume />
      </div>
    </div>
  );
}
