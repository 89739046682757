import React, { useState } from 'react'
import { Menu, Group, TextInput, ActionIcon, useMantineTheme, rem } from '@mantine/core'
import { IconArrowRight, IconBrandGoogle, IconBrandOpenai, IconMessage2, IconNote } from '@tabler/icons-react'
import { createProgramElement } from '../../services/apiProgramElements'

export function ChatBubble({ programId }) {
  const theme = useMantineTheme()
  const [selectedItem, setSelectedItem] = useState('Note')

  const [message, setMessage] = useState('');

  const handleSelect = item => {
    setSelectedItem(item)
  }

  const handleSend = async () => {
    try {
      const programElements = await createProgramElement({
        programId,
        elementBody: { message },
        type: menuValues[selectedItem]
      });

      console.log(programElements);

      // need to replace this with proper context passing
      window.postMessage({ type: 'programUpdated' }, '*');

    } catch (error) {
      console.error(error);
    }
    setMessage('');
  }

  const menuText = {
    OpenAI: 'OpenAI',
    OpenAIRAG: 'OpenAI with RAG',
    GoogleAI: 'Google AI',
    GoogleAIRAG: 'Google AI with RAG',
    RAG: 'RAG',
    Note: 'Note'
  }

  const menuValues = {
  [menuText.OpenAI]: 'openai',
  [menuText.OpenAIRAG]: 'openairag',
  [menuText.GoogleAI]: 'googleai',
  [menuText.GoogleAIRAG]: 'googleairag',
  [menuText.RAG]: 'rag',
  [menuText.Note]: 'note'
}

  const menuIcons = {
    [menuText.OpenAI]: <IconBrandOpenai style={{ width: rem(16), height: rem(16) }} stroke={1.5} />,
    [menuText.OpenAIRAG]: <IconBrandOpenai style={{ width: rem(16), height: rem(16) }} stroke={1.5} color={theme.colors.yellow[6]} />,
    [menuText.GoogleAI]: <IconBrandGoogle style={{ width: rem(16), height: rem(16) }} stroke={1.5} />,
    [menuText.GoogleAIRAG]: <IconBrandGoogle style={{ width: rem(16), height: rem(16) }} stroke={1.5} color={theme.colors.orange[6]} />,
    [menuText.RAG]: <IconMessage2 style={{ width: rem(16), height: rem(16) }} stroke={1.5} />,
    [menuText.Note]: <IconNote style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
  }

  // const ActiveIcon = menuIcons[selectedItem] || <IconMessage2 style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  const ActiveIcon = (
    <Menu withArrow width={300} position="bottom" transitionProps={{ transition: 'pop' }} withinPortal>
      <Menu.Target>
        <ActionIcon size={32} radius="xl" color="transparent">
          {menuIcons[selectedItem] || <IconMessage2 style={{ width: rem(18), height: rem(18) }} stroke={1.5} />}
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
          <Menu.Item
            onClick={() => handleSelect(menuText.OpenAI)}
            leftSection={
              <IconBrandOpenai
                style={{ width: rem(16), height: rem(16) }}
                stroke={1.5}
              />
            }
          >
            {menuText.OpenAI}
          </Menu.Item>
          <Menu.Item onClick={() => handleSelect(menuText.OpenAIRAG)} leftSection={<IconBrandOpenai style={{ width: rem(16), height: rem(16) }} stroke={1.5} color={theme.colors.yellow[6]} />}>
            {menuText.OpenAIRAG}
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            onClick={() => handleSelect(menuText.GoogleAI)}
            leftSection={
              <IconBrandGoogle
                style={{ width: rem(16), height: rem(16) }}
                stroke={1.5}
              />
            }
          >
            {menuText.GoogleAI}
          </Menu.Item>

          <Menu.Item onClick={() => handleSelect(menuText.GoogleAIRAG)} leftSection={<IconBrandGoogle style={{ width: rem(16), height: rem(16) }} stroke={1.5} color={theme.colors.orange[6]} />}>
            {menuText.GoogleAIRAG}
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item onClick={() => handleSelect(menuText.RAG)} leftSection={<IconMessage2 style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}>
            {menuText.RAG}
          </Menu.Item>

          <Menu.Divider />

          <Menu.Label>Private Notes</Menu.Label>
          <Menu.Item
            onClick={() => handleSelect(menuText.Note)}
            leftSection={
              <IconNote
                style={{ width: rem(16), height: rem(16) }}
                stroke={1.5}
              />
            }
          >
            {menuText.Note}
          </Menu.Item>
        </Menu.Dropdown>
    </Menu>
  );

  const sendButton = (
    <Group justify="center">
      <ActionIcon size={32} radius="xl" color={theme.primaryColor} variant="filled" onClick={handleSend}>
        <IconArrowRight style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
      </ActionIcon>
    </Group>
  );

  return (
    <TextInput
      radius="lg"
      size="md"
      placeholder="Type a message"
      rightSectionWidth={42}
      leftSection={ActiveIcon}
      rightSection={sendButton}
      value={message}
      onChange={event => setMessage(event.target.value)}
    />
  )
}
