import { useState } from 'react'
import { UnstyledButton, Checkbox, Text, Container } from '@mantine/core'
import classes from './Questions.module.css'

export function Questions({ questions }) {
  const [values, setValues] = useState(Array(questions.length).fill(false))

  const handleChange = index => {
    const newValues = [...values]
    newValues[index] = !newValues[index]
    setValues(newValues)
  }

  return (
    <Container size="md">
      <h3 className={classes.title}>Questions</h3>
      <div className={classes.inner}>
        <div className={classes.content}>
          {questions.map((question, index) => (
            <UnstyledButton key={index} onClick={() => handleChange(index)} className={classes.button}>
              <Checkbox checked={values[index]} onChange={() => {}} tabIndex={-1} size="md" mr="xl" styles={{ input: { cursor: 'pointer' } }} aria-hidden />

              <div>
                <Text fw={500} mb={7} lh={1}>
                  {question}
                </Text>
              </div>
            </UnstyledButton>
          ))}
        </div>
      </div>
    </Container>
  )
}
