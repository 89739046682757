import React, { useEffect, useState } from 'react';
import {
    Container,
    Title,
    Group,
    Button,
    Text,
    Box,
    rem,
    Card,
    SimpleGrid,
    useMantineTheme,
    Overlay,
    ThemeIcon,
    Paper,
    Grid,
    Avatar
} from '@mantine/core';
import { Dots } from './dots';
import classes from './Home.module.css';
import logo from '../../assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { IconAdjustmentsDollar, IconTools, IconUser, IconCheckupList, IconPhoneCall, IconBow, IconFileCode, IconFlame, IconOutbound, IconPlaneDeparture, IconBrandLinkedin } from '@tabler/icons-react';
import cx from 'clsx';
import avatarImage from '../../assets/avatar.png';

const features = [
    {
      icon: IconFileCode,
      title: 'Software Developer',
      description: '10+ years of software development experience.',
    },
    {
      icon: IconOutbound,
      title: 'Technical Recruiter',
      description: '10 years of technical recruitment experience.',
    },
    {
      icon: IconFlame,
      title: 'Sales & Marketing Manager',
      description:
        '10+ years of experience hiring and managing sales and marketing teams.',
    },
    {
      icon: IconPlaneDeparture,
      title: '2x Founder',
      description:
        'Founded two startups and was an early employee at a third.',
    },
];

export default function Home() {
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
      const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      setIsSafari(isSafariBrowser);
    }, []);

    const theme = useMantineTheme();
    const navigate = useNavigate();
    const items = features.map((feature) => (
        <div key={feature.title}>
          <ThemeIcon
            size={44}
            radius="md"
            // variant="gradient"
            // gradient={{ deg: 133, from: 'blue', to: 'cyan' }}
          >
            <feature.icon style={{ width: rem(26), height: rem(26) }} stroke={1.5} />
          </ThemeIcon>
          <Text fz="lg" mt="sm" fw={500}>
            {feature.title}
          </Text>
          <Text c="dimmed" fz="sm">
            {feature.description}
          </Text>
        </div>
    ));

    return (
        <>
        <Box>
            <header className={classes.header}>
                <Group justify="space-between" h="100%" pt={10} pb={10}>
                    <img className={classes.logo} src={logo} alt="Logo" width="70" height="70" />
                    <Group visibleFrom="sm">
                        <Button onClick={() => navigate('/login')}>Sign In</Button>
                    </Group>
                </Group>
            </header>

        </Box>
        <div className={classes.wrapper}>
            <Overlay color="#000" opacity={0.65} zIndex={1} />

            <div className={classes.inner}>
                <Title className={classes.titleBanner}>
                    Affordable Recruiting Is{' '}
                    <Text component="span" inherit className={classes.highlightBanner}>
                    Possible
                    </Text>
                </Title>
                <Container size={640}>
                    <Text size="lg" className={classes.descriptionBanner}>
                        Expert Fractional Recruiting Enhanced With AI
                    </Text>
                </Container>
                <div className={classes.controls}>
                    <Link to="/request">
                        <Button className={cx(classes.control, classes.secondaryControl)} size="lg">
                            Get started
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
        <div>
            <Container className={classes.joinWrapper} pt={120} pb={80} size={'auto'}>
                <Dots className={classes.dots} style={{ left: 0, top: 0 }} />
                <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
                <Dots className={classes.dots} style={{ left: 0, top: 140 }} />
                <Dots className={classes.dots} style={{ right: 0, top: 60 }} />
                <div className={classes.joinInner}>
                    <Title className={classes.joinTitle}>
                        We help you with every aspect of hiring
                    </Title>

                    <Container p={0} size={800}>
                    <Text size="lg" c="dimmed" className={classes.joinDescription}>
                        {'From screening resumes to building recruiting strategies to candidate prospecting, Flex provides expert recruitment services that are available in a variety of packages to fit your budget.'}
                    </Text>
                    </Container>

                    <div style={{ margin: '60px auto', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '50px' }}>
                        <Paper radius="md" withBorder style={{ maxWidth: 'calc(20rem * var(--mantine-scale))', padding: 'var(--mantine-spacing-xl)', paddingTop: 'calc(var(--mantine-spacing-xl) * 1.5 + 20px) !important' }} className={classes.pricingCard} mt={20}>
                            <ThemeIcon style={{ position: 'absolute', top: '-20px', left: 'calc(50% - 30px)' }} size={60} radius={60}>
                                <IconCheckupList style={{ width: '32px', height: '32px' }} stroke={1.5} />
                            </ThemeIcon>
                            <Text ta="center" fw={700} className={`${classes.pricingTitle} ${isSafari ? classes.safariPricingTitle : ''}`}>
                                Resume Review & Summary
                            </Text>
                            <Text c="dimmed" ta="center" fz="sm">
                                starting at $500/month
                            </Text>
                        </Paper>

                        <Paper radius="md" withBorder style={{ maxWidth: 'calc(20rem * var(--mantine-scale))', padding: 'var(--mantine-spacing-xl)', paddingTop: 'calc(var(--mantine-spacing-xl) * 1.5 + 20px) !important' }} className={classes.pricingCard} mt={20}>
                            <ThemeIcon style={{ position: 'absolute', top: '-20px', left: 'calc(50% - 30px)' }} size={60} radius={60}>
                                <IconPhoneCall style={{ width: '32px', height: '32px' }} stroke={1.5} />
                            </ThemeIcon>
                            <Text ta="center" fw={700} className={`${classes.pricingTitle} ${isSafari ? classes.safariPricingTitle : ''}`}>
                                Candidate Phone Screening
                            </Text>
                            <Text c="dimmed" ta="center" fz="sm">
                                +$1,000/month
                            </Text>
                        </Paper>

                        <Paper radius="md" withBorder style={{ maxWidth: 'calc(20rem * var(--mantine-scale))', padding: 'var(--mantine-spacing-xl)', paddingTop: 'calc(var(--mantine-spacing-xl) * 1.5 + 20px) !important' }} className={classes.pricingCard} mt={20}>
                            <ThemeIcon style={{ position: 'absolute', top: '-20px', left: 'calc(50% - 30px)' }} size={60} radius={60}>
                                <IconBow style={{ width: '32px', height: '32px' }} stroke={1.5} />
                            </ThemeIcon>
                            <Text ta="center" fw={700} className={`${classes.pricingTitle} ${isSafari ? classes.safariPricingTitle : ''}`}>
                                Active Candidate Outreach
                            </Text>
                            <Text c="dimmed" ta="center" fz="sm">
                                +$2,000/month
                            </Text>
                        </Paper>
                    </div>

                    {/* <div id={'signup'} className={classes.controls}>
                        <BetaForm />
                    </div> */}
                </div>
            </Container>
        </div>
        <div style={{ paddingTop: rem(120), paddingBottom: rem(120)}}>
            <Container className={classes.teamWrapper} size={'auto'}>
                <div className={classes.teamInner}>
                    <Title className={classes.teamTitle} style={{ fontSize: 'rem(40px)', color: 'light-dark(var(--mantine-color-black), var(--mantine-color-white))'}}>
                    We’re not consultants, we’re a{' '}
                    <Text component="span" style={{color: theme.primaryColor}} inherit>
                        part of your team
                    </Text>
                    </Title>

                    <Container p={0} size={600}>
                        <Text size="lg" c="dimmed" className={classes.teamDescription}>
                        {'A fractional recruiting service means we’re a part of your team. We’ll go through your onboarding process and we’ll attend company events;'}
                        <br />
                        {'if you invite us ;-) .'}
                        </Text>
                    </Container>
                </div>
            </Container>
        </div>
        <div style={{backgroundColor: '#000000', paddingBottom: rem(120), paddingTop: rem(80)}}>
            <Container size="lg" py="xl" className={classes.joinWrapper}>
                <Title order={2} style={{fontSize: rem(34), fontWeight: 900}} className={classes.joinTitle} ta="center" mt="sm">
                    Fractional is a better choice
                </Title>

                {/* <Text c="dimmed" className={classes.description} ta="center" mt="md">
                    From screening resumes to building recruiting strategies to candidate prospecting.
                </Text> */}

                <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
                    <Card key={'flexible'} shadow="md" radius="md" className={classes.card} padding="xl">
                        <IconAdjustmentsDollar
                            style={{ width: rem(50), height: rem(50) }}
                            stroke={2}
                            color={theme.primaryColor}
                        />
                        <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
                            {'Less cost overhead and increased flexibility'}
                        </Text>
                    </Card>

                    <Card key={'experts'} shadow="md" radius="md" className={classes.card} padding="xl">
                        <IconUser
                            style={{ width: rem(50), height: rem(50) }}
                            stroke={2}
                            color={theme.primaryColor}
                        />
                        <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
                            {'Experienced recruiters at lower costs'}
                        </Text>
                    </Card>

                    <Card key={'tools'} shadow="md" radius="md" className={classes.card} padding="xl">
                        <IconTools
                            style={{ width: rem(50), height: rem(50) }}
                            stroke={2}
                            color={theme.primaryColor}
                        />
                        <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
                            {'Complete with a suite of AI recruiting tools'}
                        </Text>
                    </Card>
                </SimpleGrid>
            </Container>
        </div>
        <div style={{paddingTop: rem(120), paddingBottom: rem(120), margin: '0% auto', maxWidth: '1140px'}}>
            <Grid gutter={80} style={{padding: '0 5%'}}>
                <Grid.Col span={{ base: 12, md: 5 }}>
                <Paper radius="md" pb="lg" bg="var(--mantine-color-body)">
                    <Avatar
                        src={avatarImage}
                        size={140}
                        radius={20}
                        // mx="auto"
                    />
                </Paper>
                <Title className={classes.recruiterTitle} order={2} style={{color: 'light-dark(var(--mantine-color-black), var(--mantine-color-white))'}}>
                    Mark, CEO <span><IconBrandLinkedin stroke={1.5} size="1.4rem" style={{color: 'white'}} /></span>
                </Title>
                <Text c="dimmed">
                    360 degrees of hiring experience.
                </Text>

                <Link to="/request">
                    <Button style={{marginTop: rem(10)}} className={cx(classes.control, classes.secondaryControl)} size="lg">
                            Request a free consultation
                    </Button>
                </Link>

                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 7 }}>
                <SimpleGrid cols={{ base: 1, md: 2 }} spacing={30}>
                    {items}
                </SimpleGrid>
                </Grid.Col>
            </Grid>
        </div>
        <footer className={classes.footer}>
            <Container style={{borderTop: rem(1), borderTopStyle: 'solid', borderTopColor: 'light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4))'}} className={classes.afterFooter}>
                <Text c="dimmed" size="sm">
                © 2024 Flex inc. All rights reserved.
                </Text>
            </Container>
        </footer>
        </>
    );
}