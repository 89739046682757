import React from 'react'
import { NAVBAR_WIDTH } from '../../constants'

const Wrapper = ({ children }) => (
  <div
    style={{
      display: 'flex',
      '--navbar-width': NAVBAR_WIDTH
    }}
  >
    <div
      style={{
        marginLeft: 'var(--navbar-width)',
        width: 'calc(100vw - var(--navbar-width))',
        height: '100vh'
      }}
    >
      {children}
    </div>
  </div>
)

export default Wrapper
