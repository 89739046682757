import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, MantineProvider, rem } from '@mantine/core';

const theme = createTheme({
  colorScheme: 'dark',
  primaryColor: 'cyan',
  colors: {
    primary: ['#E9EDFC', '#C1CCF6', '#99ABF0' /* ... */],
    // Add your color
    deepBlue: ['#E9EDFC', '#C1CCF6', '#99ABF0' /* ... */],
    // or replace default theme color
    blue: ['#E9EDFC', '#C1CCF6', '#99ABF0' /* ... */],
  },

  shadows: {
    md: '1px 1px 3px rgba(0, 0, 0, .25)',
    xl: '5px 5px 3px rgba(0, 0, 0, .25)',
  },

  headings: {
    fontFamily: 'Roboto, sans-serif',
    sizes: {
      h1: { fontSize: rem(36) },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <MantineProvider theme={theme} defaultColorScheme="dark">
      <div className="App">
      <App />
      </div>
    </MantineProvider>
    </BrowserRouter>
  </React.StrictMode>
);