import React, { useState } from 'react';
import {
  TextInput,
  PasswordInput,
  Checkbox,
  // Text,
  // Anchor,
  Paper,
  Title,
  Container,
  Group,
  Button,
} from '@mantine/core';
import classes from './LoginForm.module.css'

export function LoginForm({ onSubmit }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ username, password });
  };

  return (
    <Container size={420} my={40}>
      <Title ta="center" className={classes.title}>
        Welcome back!
      </Title>
      {/* <Text c="dimmed" size="sm" ta="center" mt={5}>
        Do not have an account yet?{' '}
        <Anchor size="sm" component="button">
          Create account
        </Anchor>
      </Text> */}
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={handleSubmit}>
          <TextInput
            label="Username"
            placeholder="Enter your username"
            value={username}
            onChange={(event) => setUsername(event.currentTarget.value)}
            autoFocus
            name="username"
          />
          <PasswordInput
            label="Password"
            placeholder="Enter your password"
            mt="md"
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            name="password"
          />
          <Group justify="space-between" mt="lg">
            <Checkbox label="Remember me" />
            {/* <Anchor component="button" size="sm">
              Forgot password?
            </Anchor> */}
          </Group>
          <Button type="submit" mt="lg" fullWidth>
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
