// apiCandiadates.js
import store from './store.js'
import { refreshAccessToken, GOFLEX_PROGENITOR_ENDPOINT } from './apiAuth.js'

let num401Responses = 0

export const getCandidates = async (params) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when getting candidates')
    throw new Error('No auth data found when getting candidates')
  }

  try {
    const queryString = new URLSearchParams(params).toString()
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/candidates?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting candidates')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getCandidates(params)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting candidates: ${errorMessage}`)
    }

    const candidates = await response.json()

    if (!candidates) {
      throw new Error('No candidates returned when getting candidates')
    }

    // Set candidates data in the store
    store.setCandidates(candidates)

    return candidates
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const getCandidateById = async (candidateId, params) => {
  const authData = store.getAuthData()

  if (!authData) {
    throw new Error('No auth data found when getting candidate by ID')
  }

  try {
    const queryString = new URLSearchParams(params).toString()

    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/candidates/${candidateId}?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting candidate by ID')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getCandidateById(candidateId, params)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting candidate by ID: ${errorMessage}`)
    }

    const candidate = await response.json()

    if (!candidate) {
      return null
    }

    store.upsertCandidate(candidate)

    return candidate
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const reEvaluateCandidate = async (candidateId, jobPostingId) => {
  const authData = store.getAuthData()

  if (!authData) {
    throw new Error('No auth data found when reevaluating candidate')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/candidates/${candidateId}/job_postings/${jobPostingId}/reevaluate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when reevaluating candidate')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return reEvaluateCandidate(candidateId, jobPostingId)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error reevaluating candidate: ${errorMessage}`)
    }

    const candidate = await response.json()

    if (!candidate) {
      return null
    }

    store.upsertCandidate(candidate)

    return candidate
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const createCandidate = async ({ firstName, lastName, email, currentCompany, phoneNumber, linkedinProfile }) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when creating a candidate')
    throw new Error('No auth data found when creating a candidate')
  }

  const data = {
    firstName,
    lastName,
    email,
    currentCompany,
    phoneNumber,
    linkedinProfile
  };

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/candidates`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when creating a candidate')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return createCandidate(data)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error creating candidate: ${errorMessage}`)
    }

    const candidate = await response.json()

    if (!candidate) {
      throw new Error('No candidate returned when creating candidate')
    }

    store.addCandidate(candidate)

    return candidate
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const updateCandidate = async (candidateId, candidateData) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when updating a candidate')
    throw new Error('No auth data found when updating a candidate')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/candidates/${candidateId}`, {
      method: 'PUT',
      body: JSON.stringify(candidateData),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when updating a candidate')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return updateCandidate(candidateId, candidateData)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error updating candidate: ${errorMessage}`)
    }

    const candidate = await response.json()

    if (!candidate) {
      throw new Error('No candidate returned when updating candidate')
    }

    store.upsertCandidate(candidate)

    return candidate
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const deleteCandidate = async (candidateId) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when deleting a candidate')
    throw new Error('No auth data found when deleting a candidate')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/candidates/${candidateId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when deleting a candidate')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return deleteCandidate(candidateId)
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error deleting candidate: ${errorMessage}`)
    }

    store.removeCandidate(candidateId)

    return true
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
