// Login.jsx
import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoginForm } from '../Components/LoginForm/LoginForm.jsx';
import { UserContext } from '../contexts/UserProvider.jsx';
import { login } from '../services/apiAuth.js';

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useContext(UserContext);

  const handleSubmit = async ({ username, password }) => {
    try {
      const data = await login(username, password);
      setUser(data);
      navigate(location.state?.from || '/jobs');
    } catch (error) {
      console.error(error);
    }
  };

  return <LoginForm onSubmit={handleSubmit} />;
}

export default Login;
