import React, { useEffect, useState } from 'react';
import { Grid, Textarea } from '@mantine/core';
import { CustomerChatBubble } from '../CustomerChatBubble/CustomerChatBubble';
import { getProgramElementsByProgramId } from '../../services/apiProgramElements';
import { CustomerComment } from '../CustomerComment/CustomerComment';
import InteractiveComponentWrapper from '../InteractiveComponentWrapper/InteractiveComponentWrapper';

export default function JobDescription({ description, program, visible }) {
    const [jobDescription, setJobDescription] = useState('');
    const [reverseProgramElements, setReverseProgramElements] = useState([]);

    useEffect(() => {
        if (description) {
            setJobDescription(description ?? '');
        }
    }, [description]);

    useEffect(() => {
        const fetchProgramElements = async () => {
            try {
                const elements = await getProgramElementsByProgramId({ programId: program.id });
                setReverseProgramElements(elements.sort().reverse())
              } catch (error) {
                console.error(error);
              }
        }
        if (program && program.id) {
            fetchProgramElements();
        }
    }, [program, program.id]);

    return (
        <InteractiveComponentWrapper visible={visible}>
            <Grid style={{
                padding: 'var(--mantine-spacing-md)',
            }}>

                <Grid.Col span={6}>
                    <Textarea
                        name="jobDescription"
                        label="Job Description"
                        size="lg"
                        minRows={25}
                        autosize
                        maxRows={100}
                        value={jobDescription}
                        onChange={(event) => setJobDescription(event.currentTarget.value)}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <Grid>
                        <Grid.Col span={12}>
                            <div style={{marginTop: '30px'}}></div>
                            <CustomerChatBubble programId={program?.id} />
                            <div style={{marginTop: '30px'}}></div>
                            <CustomerComment
                                comments={reverseProgramElements}
                            />
                        </Grid.Col>
                    </Grid>

                </Grid.Col>

            </Grid>
        </InteractiveComponentWrapper>

    );
}
