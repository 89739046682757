import { Grid } from '@mantine/core'
import React from 'react'
import classes from './ResumeCustomerView.module.css'
import { CustomerComment } from '../CustomerComment/CustomerComment'
import { CustomerChatBubble } from '../CustomerChatBubble/CustomerChatBubble'

export default function ResumeCustomerView({ candidate }) {
  const iframeSrc = candidate && candidate.resumes && candidate.resumes[0] ? candidate.resumes[0].resumePathHtml : '/DenizCan3.html';

  return (
    <div>
      <Grid className={`${classes.row}`}>
        <Grid.Col span={6} className={classes.resume}>
          <h3>Resume</h3>
          <iframe src={iframeSrc} title="Resume" style={{ width: '100%', height: '100%' }} />
        </Grid.Col>
        <Grid.Col span={6}>
          <h3>Chat</h3>
          <Grid>
            <Grid.Col span={12}>
                <CustomerChatBubble />
                <CustomerComment
                    comments={[{
                        createdByAvatar: 'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-1.png',
                        createdByName: 'Hiring Manager Harry',
                        createdAt: '2024-05-01',
                        body: { message: 'We\'ve got a person on the team from Kyiv Polytech and they\'re a top performer. Let\'s schedule an interview!' }
                    }]}
                />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </div>
  )
}
