// apiProgramElements.js
import store from './store.js'
import { refreshAccessToken, GOFLEX_PROGENITOR_ENDPOINT } from './apiAuth.js'

let num401Responses = 0

export const createProgramElement = async ({ programId, elementBody, type }) => {
  console.log(`Creating program element with programId: ${programId} and elementBody: ${elementBody}`)

  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when creating a program element')
    throw new Error('No auth data found when creating a program element')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/program_elements`, {
      method: 'POST',
      body: JSON.stringify({
        programId,
        body: elementBody,
        type
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when creating a program element')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return createProgramElement({ programId, elementBody, type })
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error creating program element: ${errorMessage}`)
    }

    const programElement = await response.json()

    if (!programElement) {
      throw new Error('No program element returned when creating program element')
    }

    return programElement
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const updateProgramElement = async ({ programId, programElementId, elementBody, type }) => {
  console.log(`Updating program element with programElementId: ${programElementId} and elementBody: ${elementBody}`)

  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when updating a program element')
    throw new Error('No auth data found when updating a program element')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/program_elements/${programElementId}`, {
      method: 'PUT',
      body: JSON.stringify({
        programId,
        body: elementBody,
        type
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when updating a program element')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return updateProgramElement({ programId, programElementId, elementBody, type })
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error updating program element: ${errorMessage}`)
    }

    const programElement = await response.json()

    if (!programElement) {
      throw new Error('No program element returned when updating program element')
    }

    return programElement
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const markAsRAGProgramElement = async ({ programId, programElementId }) => {
  console.log(`Marking program element as RAG with programElementId: ${programElementId}`)

  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when marking a program element as RAG')
    throw new Error('No auth data found when marking a program element as RAG')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/program_elements/${programElementId}/mark_as_rag`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when marking a program element as RAG')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return markAsRAGProgramElement({ programId, programElementId })
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error marking program element as RAG: ${errorMessage}`)
    }

    const programElement = await response.json()

    if (!programElement) {
      throw new Error('No program element returned when marking program element as RAG')
    }

    return programElement
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

export const getProgramElementsByProgramId = async ({ programId }) => {
  const authData = store.getAuthData()

  if (!authData) {
    console.log('No auth data found when getting a program by id')
    throw new Error('No auth data found when getting a program by id')
  }

  try {
    const response = await fetch(`${GOFLEX_PROGENITOR_ENDPOINT}/program_elements/programs/${programId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authData.tokenType} ${authData.accessToken}`
      }
    })

    if (response.status === 401) {
      num401Responses++

      if (num401Responses > 3) {
        num401Responses = 0
        throw new Error('Too many 401 responses when getting a program by id')
      }

      console.log('GoFlex: Access token expired, refreshing...')
      await refreshAccessToken()
      return getProgramElementsByProgramId({ programId })
    }

    num401Responses = 0

    if (!response.ok) {
      const errorResponse = await response.json()
      const errorMessage = errorResponse.errors[0]
      throw new Error(`Error getting program by id: ${errorMessage}`)
    }

    const program = await response.json()

    if (!program) {
      throw new Error('No program returned when getting program by id')
    }

    return program
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
