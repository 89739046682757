import React from 'react';
import Wrapper from '../Components/Wrapper/Wrapper';
import { Grid } from '@mantine/core';
import '@mantine/core/styles.css';

export default function RAGEntry() {
    return (
        <Wrapper>
            <Grid style={{
                padding: 'var(--mantine-spacing-md)'
            }}>
                <Grid.Col span={12}>
                    <h2>RAG</h2>
                </Grid.Col>
            </Grid>
    </Wrapper>
    );
};