// PrivateRoutes.js
import { useContext, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserContext } from '../../contexts/UserProvider.jsx'
import { Navbar } from '../Navbar/Navbar.js'
import store from '../../services/store.js'

const PrivateRoutes = () => {
  const { user, setUser, loading } = useContext(UserContext)

  useEffect(() => {
    const authData = store.getAuthData()
    if (authData.accessToken && (!user || user.accessToken !== authData.accessToken)) {
      setUser(authData)
    }
  }, [user, setUser])

  if (loading) {
    return <div>Loading...</div>
  }

  return user ? (
    <>
      <Navbar />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ from: window.location.pathname }} />
  )
}

export default PrivateRoutes
