import { useEffect, useState } from 'react'
import { Table, ScrollArea, UnstyledButton, Group, Text, Center, TextInput, rem } from '@mantine/core'
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react'
import classes from './CandidateList.module.css'
import { useNavigate } from 'react-router-dom'
import { getCandidates } from '../../services/apiCandidates'

function Th({ children, reversed, sorted, onSort }) {
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector

  return (
    <Table.Th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  )
}

function filterData(data, query) {
  if (!data.length) {
    return [];
  }

  const keys = Object.keys(data[0]);
  const lowerCaseQuery = query.toLowerCase();

  return data.filter((item) =>
    keys.some((key) => String(item[key]).toLowerCase().includes(lowerCaseQuery))
  );
}

function sortData(data, payload) {
  const { sortBy } = payload

  if (!sortBy) {
    return filterData(data, payload.search)
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy])
      }

      return a[sortBy].localeCompare(b[sortBy])
    }),
    payload.search
  )
}
export function CandidateList(id) {

  const [search, setSearch] = useState('')
  const [sortedData, setSortedData] = useState([])
  const [sortBy, setSortBy] = useState(null)
  const [reverseSortDirection, setReverseSortDirection] = useState(false)
  const [initialData, setInitialData] = useState([])
  const navigate = useNavigate()

  // get the candidate data
  useEffect(() => {
    const fetchCandidates = async () => {
      const response = await getCandidates();
      setInitialData(response);
      setSortedData(response);
    }
    fetchCandidates();
  }, []);

  const setSorting = field => {
    const reversed = field === sortBy ? !reverseSortDirection : false
    setReverseSortDirection(reversed)
    setSortBy(field)
    const newSortedData = sortData(initialData, { sortBy: field, reversed, search })
    setSortedData(newSortedData)
  }

  const handleSearchChange = event => {
    const { value } = event.currentTarget
    setSearch(value)
    const newSortedData = sortData(initialData, { sortBy, reversed: reverseSortDirection, search: value })
    setSortedData(newSortedData)
  }

const rows = sortedData.map(row => {
  const firstResume = row.resumes?.[0];
  const evaluationScore = firstResume ? firstResume.evaluationScore : 'N/A'; // Default value if no resume

  return (
    <Table.Tr key={row.id} onClick={() => navigate(`/candidates/${row.id}`)} style={{ cursor: 'pointer' }}>
      <Table.Td>{row.fullName}</Table.Td>
      <Table.Td>{row.email}</Table.Td>
      <Table.Td>{row.currentCompany}</Table.Td>
      <Table.Td>{evaluationScore}</Table.Td>
    </Table.Tr>
  );
});

  return (
    <ScrollArea>
      <TextInput placeholder="Search by any field" mb="md" leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />} value={search} onChange={handleSearchChange} />
      <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed">
        <Table.Tbody>
          <Table.Tr>
            <Th sorted={sortBy === 'fullName'} reversed={reverseSortDirection} onSort={() => setSorting('fullName')}>
            Full Name
            </Th>
            <Th sorted={sortBy === 'email'} reversed={reverseSortDirection} onSort={() => setSorting('email')}>
              Email
            </Th>
            <Th sorted={sortBy === 'company'} reversed={reverseSortDirection} onSort={() => setSorting('company')}>
              Company
            </Th>
          </Table.Tr>
        </Table.Tbody>
        <Table.Tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <Table.Tr>
              <Table.Td colSpan={sortedData[0] ? Object.keys(sortedData[0]).length : 0}>
                <Text fw={500} ta="center">
                  Nothing found
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  )
}
