import React, { useState, useEffect } from 'react'
import { Paper, Text, Collapse } from '@mantine/core'
import { IconCheck, IconX, IconMessage2, IconMessage2Plus, IconStar, IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { Comment } from '../Comment/Comment'
import classes from './FlexComment.module.css'
import { ChatBubble } from '../ChatBubble/ChatBubble'

function DataCard({ documentText }) {
  return (
    <Paper withBorder radius="md" className={classes.card} style={{ position: 'relative', width: '92%', marginLeft: 'auto' }}>
      <Text size="sm" mt="sm" c="dimmed" mb="md">
        Data: {documentText}
      </Text>
    </Paper>
  )
}

export const FlexComment = React.forwardRef(({ program, isExpanded }, ref) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isDataCardExpanded, setIsDataCardExpanded] = useState(false)
  const [isCommentExpanded, setIsCommentExpanded] = useState(isExpanded)
  const [isCommentIconHovered, setIsCommentIconHovered] = useState(false)

  useEffect(() => {
    setIsCommentExpanded(isExpanded);
  }, [isExpanded]);

  const IconStarFilledGradient = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-star"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#FFC107', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#FF5722', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path stroke="none" fill="url(#gradient)" d="M12 2l3.09 6.26L22 9.27l-5 4.87L18.18 22 12 18.54 5.82 22 7 14.14l-5-4.87 6.91-1.01L12 2z" />
    </svg>
  )

  const handleChevronClick = () => {
    setIsDataCardExpanded(!isDataCardExpanded)
  }

  const handleCommentClick = () => {
    setIsCommentExpanded(!isCommentExpanded)
  }

  return (
    <div ref={ref} style={{width: 'inherit'}}>
      <Paper withBorder radius="md" className={classes.card} style={{ position: 'relative' }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <div className={classes.iconContainer}>
          <IconCheck />
          <IconX />
        </div>
        <div>{isHovered ? <IconStarFilledGradient /> : <IconStar />}</div>
        <Text size="xl" fw={500} mt="md">
          {program.name}
        </Text>
        <Text size="sm" mt="sm" c="dimmed" mb="md">
          {`Context Text: ${program.contextText}`}
        </Text>
        <div className={classes.menuContainer}>
          {isDataCardExpanded ? <IconChevronUp onClick={handleChevronClick} /> : <IconChevronDown onClick={handleChevronClick} />}
          <div onMouseEnter={() => setIsCommentIconHovered(true)} onMouseLeave={() => setIsCommentIconHovered(false)}>
            {isCommentIconHovered ? <IconMessage2Plus onClick={handleCommentClick} /> : <IconMessage2 onClick={handleCommentClick} />}
          </div>
        </div>
      </Paper>
      <Collapse in={isDataCardExpanded} transitionDuration={250}>
        <DataCard documentText={program.documentText} />
      </Collapse>
      <Collapse in={isCommentExpanded} transitionDuration={250}>
        <div>
          <ChatBubble programId={program.id} />
          <Comment
            comments={program.elements ? program.elements.map(element => ({
              avatar: element.createdByAvatar,
              name: element.createdByName,
              time: timeAgo(element.createdAt),
              content: element.body.message
            })) : []}
          />
        </div>
      </Collapse>
    </div>
  )
})
FlexComment.displayName = 'FlexComment';

export function timeAgo(date) {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000)

  let interval = Math.floor(seconds / 31536000)

  if (interval >= 1) {
    return interval + ' year' + (interval === 1 ? '' : 's') + ' ago'
  }
  interval = Math.floor(seconds / 2592000)
  if (interval >= 1) {
    return interval + ' month' + (interval === 1 ? '' : 's') + ' ago'
  }
  interval = Math.floor(seconds / 86400)
  if (interval >= 1) {
    return interval + ' day' + (interval === 1 ? '' : 's') + ' ago'
  }
  interval = Math.floor(seconds / 3600)
  if (interval >= 1) {
    return interval + ' hour' + (interval === 1 ? '' : 's') + ' ago'
  }
  interval = Math.floor(seconds / 60)
  if (interval >= 1) {
    return interval + ' minute' + (interval === 1 ? '' : 's') + ' ago'
  }
  return 'Just now'
}
