import { Grid } from '@mantine/core'
import React, { useEffect, useState, createRef, useRef } from 'react'
import classes from './Resume.module.css'
import { FlexComment } from '../FlexComment/FlexComment'
import { getPrograms } from '../../services/apiPrograms'

export default function Resume() {
  const [programs, setPrograms] = useState([])
  const [programRefs, setProgramRefs] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null)

  // const iframeSrc = '/ForkliftOperatorResume1.html';
  // const iframeSrc = '/ValPorterWithoutGoogle.html';
  const iframeSrc = '/DenizCan3.html';

  // Create refs to store the current values
  const programsRef = useRef();
  const programRefsRef = useRef();

  useEffect(() => {
    const fetchPrograms = async () => {
      const programs = await getPrograms(iframeSrc)
      setPrograms(programs)
      const newProgramRefs = Array(programs.length).fill().map((_, i) => (programRefsRef.current && programRefsRef.current[i]) || createRef());
      setProgramRefs(newProgramRefs);

      // Update the refs with the new values
      programsRef.current = programs;
      programRefsRef.current = newProgramRefs;
    }

    const handleEvent = (event) => {
      if (event.data.type === 'programCreated' || event.data.type === 'programUpdated') {
        fetchPrograms()
      } else if (event.data.type === 'programSelected') {
        setSelectedProgram(event.data.program)
        const programIndex = programsRef.current.findIndex(program => program.id === event.data.program.id);
        programRefsRef.current[programIndex].current.scrollIntoView({ behavior: 'smooth' });
      }
    }

    window.addEventListener('message', handleEvent);

    fetchPrograms()

    return () => {
      window.removeEventListener('message', handleEvent);
    }
  }, [])

  return (
    <div>
      <Grid className={`${classes.row}`}>
        <Grid.Col span={6} className={classes.resume}>
          <h3>Resume</h3>
          <iframe src={iframeSrc} title="Resume" style={{ width: '100%', height: '100%' }} />
        </Grid.Col>
        <Grid.Col span={6}>
          <h3>Chat</h3>
          <Grid className={`${classes.inner}`}>
            {programs.map((program, index) => (
              <FlexComment
                key={index}
                ref={programRefs[index]}
                program={program}
                isExpanded={selectedProgram && selectedProgram.id === program.id}
              />
            ))}
          </Grid>
        </Grid.Col>
      </Grid>
    </div>
  )
}
