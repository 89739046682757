import { Text, Avatar, Group, TypographyStylesProvider, Paper } from '@mantine/core'
import classes from './Comment.module.css'

export function Comment({ comments }) {
  return (
    <>
      {comments.map((comment, index) => (
        <Paper key={index} withBorder radius="md" style={{ position: 'relative', width: '92%', marginLeft: 'auto' }} className={classes.comment}>
          <Group>
            <Avatar src={comment.avatar} alt={comment.name} radius="xl" />
            <div>
              <Text fz="sm">{comment.name}</Text>
              <Text fz="xs" c="dimmed">
                {comment.time}
              </Text>
            </div>
          </Group>
          <TypographyStylesProvider className={classes.body}>
            <div className={classes.content} dangerouslySetInnerHTML={{ __html: comment.content }} />
          </TypographyStylesProvider>
        </Paper>
      ))}
    </>
  )
}
