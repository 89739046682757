import cx from 'clsx';
import { useState, useEffect } from 'react';
import {
  Container,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Menu,
  Tabs,
  Burger,
  Center,
  TextInput,
  rem,
  Space,
  Button
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch,
    IconSettings,
    IconPlayerPause,
    IconTrash,
    IconSwitchHorizontal,
} from '@tabler/icons-react';
import classes from './JobControlTower.module.css';
import { useNavigate }  from 'react-router-dom';
import { getJobPostingById } from '../../services/apiJobPostings';
import { getCandidates } from '../../services/apiCandidates';

const tabs = [
    'Candidates',
    'Interviews',
    'Description',
    'Stakeholders',
    'Settings',
    'Add Candidate',

];

function Th({ children, reversed, sorted, onSort }) {
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;

    return (
      <Table.Th className={classes.th}>
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group justify="space-between">
            <Text fw={500} fz="sm">
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
            </Center>
          </Group>
        </UnstyledButton>
      </Table.Th>
    );
}

function filterData(data, query) {
  if (!data.length) {
    return [];
  }

  const keys = Object.keys(data[0]);
  const lowerCaseQuery = query.toLowerCase();

  return data.filter((item) =>
    keys.some((key) => String(item[key]).toLowerCase().includes(lowerCaseQuery))
  );
}

function sortData(data, payload) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search
  );
}

export function JobControlTower({ id }) {
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [title, setTitle] = useState('');
  const [initialData, setInitialData] = useState([]);
  const navigate = useNavigate();

  const [opened, { toggle }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  // get job title
  useEffect(() => {
    const fetchJobData = async () => {
      const response = await getJobPostingById(id);
      setTitle(response.jobTitle);
    }
    fetchJobData();
  }, [id]);

  // get the candidate list
  useEffect(() => {
    const fetchCandidates = async () => {
      const response = await getCandidates({ job_posting_id: id });
      setInitialData(response);
      setSortedData(response);
    }
    fetchCandidates();
  }, [id]);

  const items = tabs.map((tab) => (
    <Tabs.Tab value={tab} key={tab}>
      {tab}
    </Tabs.Tab>
  ));

const setSorting = (field) => {
  const reversed = field === sortBy ? !reverseSortDirection : false;
  setReverseSortDirection(reversed);
  setSortBy(field);
  const newSortedData = sortData(initialData, { sortBy: field, reversed, search });
  setSortedData(newSortedData);
};

const handleSearchChange = (event) => {
  const { value } = event.currentTarget;
  setSearch(value);
  const newSortedData = sortData(initialData, { sortBy, reversed: reverseSortDirection, search: value });
  setSortedData(newSortedData);
};

const rows = sortedData.map((row) => {
  const firstResume = row.resumes?.[0];
  const evaluationScore = firstResume ? firstResume.evaluationScore : 'N/A'; // Default value if no resume

  return (
    <Table.Tr
      key={row.id}
      onClick={() => navigate(`/jobs/${id}/candidates/${row.id}`)}
      style={{ cursor: 'pointer' }}
    >
      <Table.Td>{row.fullName}</Table.Td>
      <Table.Td>{row.email}</Table.Td>
      <Table.Td>{row.currentCompany}</Table.Td>
      <Table.Td>{evaluationScore}</Table.Td>
    </Table.Tr>
  );
});

  return (
    <>
    <div className={classes.header}>
      <Container className={classes.mainSection} size="auto" style={{marginLeft: '5px'}}>
        <Group justify="space-between">

          <Burger opened={opened} onClick={toggle} hiddenFrom="xs" size="sm" />

          <Menu
            width={260}
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
              >
                <Group gap={7}>
                  <Text fw={500} size="sm" lh={1} mr={3}>
                    {title}
                  </Text>
                  <IconChevronDown style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Settings</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconSettings style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                }
                onClick={() => navigate(`/job/edit/${id}`)}
              >
                Job settings
              </Menu.Item>
              <Menu.Item
                leftSection={
                  <IconSwitchHorizontal style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                }
              >
                Switch jobs
              </Menu.Item>

              <Menu.Divider />

              <Menu.Label>Actions</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconPlayerPause style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                }
              >
                Close job
              </Menu.Item>
              <Menu.Item
                color="red"
                leftSection={<IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
              >
                Delete job
              </Menu.Item>
              <Menu.Label>Add Candidate</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconSettings style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                }
                onClick={() => navigate(`/jobs/${id}/addcandidate`)}
              >
                Add Candidate
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Button variant="default" radius="xl" size="md">
            Re-Evaluate All
          </Button>
        </Group>
      </Container>
      <Container size="md" style={{marginLeft: '0'}}>
        <Tabs
          defaultValue="Home"
          variant="outline"
          visibleFrom="sm"
          classNames={{
            root: classes.tabs,
            list: classes.tabsList,
            tab: classes.tab,
          }}
        >
          <Tabs.List>{items}</Tabs.List>
        </Tabs>
      </Container>
    </div>
    <Space h="xl" />
    <ScrollArea>
      <TextInput
        placeholder="Search by any field"
        mb="md"
        leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
        value={search}
        onChange={handleSearchChange}
      />
      <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed">
        <Table.Tbody>
          <Table.Tr>
            <Th
              sorted={sortBy === 'fullName'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('fullName')}
            >
              Full Name
            </Th>
            <Th
              sorted={sortBy === 'email'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('email')}
            >
              Email
            </Th>
            <Th
              sorted={sortBy === 'company'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('company')}
            >
              Company
            </Th>
            <Th
              sorted={sortBy === 'evaluationScore'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('evaluationScore')}
            >
              Score
            </Th>
          </Table.Tr>
        </Table.Tbody>
        <Table.Tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <Table.Tr>
              <Table.Td colSpan={sortedData[0] ? Object.keys(sortedData[0]).length : 0}>
                <Text fw={500} ta="center">
                  Nothing found
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </ScrollArea>
    </>
  );
}
